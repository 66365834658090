import React from "react";
import { Caption, CaptionProps, DayPicker } from "react-day-picker";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import { getMonthsBetween, getWeekDayName, isSameDay } from "../../../common/dateUtils";
import NoScrollCalenderCaptions from "../../Common/Calender/NoScrollCalenderCaptions";
import { Accordion } from "react-bootstrap";

type AvailableSiteDateAccordionItemProps = {
    availableSiteDates: AvailableSiteDate[];
    applicationPeriod: ApplicationPeriod;
};

export default function AvailableSiteDateAccordionItem({
    availableSiteDates,
    applicationPeriod
}: AvailableSiteDateAccordionItemProps) {
    const displayedMonths: Date[] = getMonthsBetween(applicationPeriod.occasionsFrom, applicationPeriod.occasionsTo);

    const calenderElements = displayedMonths.map((month) => (
        <div className="col-xxl-3 col-md-4" key={month.getTime()}>
            <DayPicker
                defaultMonth={month}
                weekStartsOn={1}
                formatters={{ formatWeekdayName: getWeekDayName }}
                components={{ Caption: NoScrollCalenderCaptions }}
                selected={(d) => availableSiteDates.some((sa) => isSameDay(sa.availableDate, d))}
            />
        </div>
    ));

    return (
        <Accordion.Item eventKey="AvailableSiteDates">
            <Accordion.Header>
                <span className="fw-bold">Tillgänglighet</span>
            </Accordion.Header>
            <Accordion.Body>
                <div className="accordion-body row">{calenderElements}</div>
            </Accordion.Body>
        </Accordion.Item>
    );
}
