import React from "react";
import Application from "../../../types/Application";
import { getRemainingNumberOfOccasionsToPlan, getTotalNumberOfOccasions } from "../../../common/applicationUtils";
import PlannedApplicationSite from "../../../types/PlannedApplicationSite";

type ApplicationTableRowProps = {
    application: Application;
    plannedApplicationSites: PlannedApplicationSite[];
};

function ApplicationTableRow({ application, plannedApplicationSites }: ApplicationTableRowProps) {
    const rowClickHandler = () => {
        window.location.href = `/applications/${application.id}/siteSelection`;
    };

    return (
        <tr onClick={rowClickHandler} style={{ cursor: "pointer" }}>
            <td>{application.actorName}</td>
            <td>{application.document.eventName}</td>
            <td>{application.groupedAgeTags.join(", ")}</td>
            <td>
                <input
                    className="form-control text-end pe-0"
                    type="number"
                    disabled
                    value={getTotalNumberOfOccasions(application)}
                />
            </td>
            <td>
                <input
                    className="form-control text-end pe-0"
                    type="number"
                    disabled
                    value={getRemainingNumberOfOccasionsToPlan(application)}
                />
            </td>
        </tr>
    );
}

export default ApplicationTableRow;
