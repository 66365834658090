import React from "react";
import { CaptionProps } from "react-day-picker";
import { getCaptionText } from "../../../common/dateUtils";

export default function NoScrollCalenderCaptions({ displayMonth }: CaptionProps) {
    return (
        <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
                <div
                    className="rdp-caption_label"
                    aria-live="polite"
                    role="presentation"
                    id="react-day-picker-1"
                    style={{ width: "9rem" }}
                >
                    {getCaptionText(displayMonth)}
                </div>
            </div>
        </div>
    );
}
