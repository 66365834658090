import React, { useEffect, useState } from "react";
import Application from "../../../types/Application";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import {
    applyApplicationPlanningFilter,
    getAdjustedAgeTagsWithSelectedOccasions
} from "../../../common/applicationUtils";
import { pageLoadErrorMessage } from "../../../common/errorMessages";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorMessage from "../../Common/Messages/ErrorMessage";
import InfoMessage from "../../Common/Messages/InfoMessage";
import ApplicationTableRow from "./ApplicationTableRow";
import {
    fetchApplicationPeriod,
    fetchPlannedApplicationSitesByApplicationPeriodId,
    fetchPlanningCommentsByApplicationPeriodId,
    fetchSelectedApplicationsByApplicationPeriodId,
    fetchSitesWithPlannedOccasions
} from "../../../common/apiClient";
import PlannedApplicationSite from "../../../types/PlannedApplicationSite";
import ApplicationPlanningFilter, { ApplicationPlanningFilterOptions } from "./ApplicationPlanningFilter";
import PlanningOverviewAccordionItem from "../PlanningOverviewAccordionItem";
import PlanningComment from "../../../types/PlanningComment";
import { Accordion } from "react-bootstrap";
import { toGroupedAgeTags } from "../../../common/stringUtils";
import { sortPlannedApplicationSites } from "../../../common/planningUtils";

type ApplicationPlanningProps = {
    applicationPeriodId: number;
};

function ApplicationPlanning({ applicationPeriodId }: ApplicationPlanningProps) {
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [applications, setApplications] = useState<Application[]>([]);
    const [plannedApplicationSites, setPlannedApplicationSites] = useState<PlannedApplicationSite[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<ApplicationPlanningFilterOptions>();
    const [planningComments, setPlanningComments] = useState<PlanningComment[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const [
                applicationPeriodFetchResult,
                applicationsFetchResult,
                plannedApplicationSitesFetchResult,
                sitesFetchResult,
                planningCommentsFetchResult
            ] = await Promise.all([
                fetchApplicationPeriod(applicationPeriodId),
                fetchSelectedApplicationsByApplicationPeriodId(applicationPeriodId),
                fetchPlannedApplicationSitesByApplicationPeriodId(applicationPeriodId),
                fetchSitesWithPlannedOccasions(applicationPeriodId),
                fetchPlanningCommentsByApplicationPeriodId(applicationPeriodId)
            ]);

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationsFetchResult.errorMessage) {
                console.error(applicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (plannedApplicationSitesFetchResult.errorMessage) {
                console.error(plannedApplicationSitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (sitesFetchResult.errorMessage) {
                console.error(sitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (planningCommentsFetchResult.errorMessage) {
                console.error(planningCommentsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            applicationsFetchResult.value.forEach((a) => {
                const adjustedAgeTags = getAdjustedAgeTagsWithSelectedOccasions(a, false);
                a.groupedAgeTags = toGroupedAgeTags(adjustedAgeTags);
            });

            plannedApplicationSitesFetchResult.value.forEach((pas) => {
                pas.site = sitesFetchResult.value.find((s) => s.id === pas.siteId);
                pas.application = applicationsFetchResult.value.find((a) => a.id === pas.applicationId);
            });

            plannedApplicationSitesFetchResult.value = plannedApplicationSitesFetchResult.value.filter(
                (pas) => pas.application && pas.site
            );

            setApplicationPeriod(applicationPeriodFetchResult.value);
            setApplications(applicationsFetchResult.value);
            setPlannedApplicationSites(sortPlannedApplicationSites(plannedApplicationSitesFetchResult.value));
            setPlanningComments(planningCommentsFetchResult.value);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    if (applications.length === 0) {
        return (
            <>
                <header>
                    <h2>
                        Programläggning - evenemang: spelperiod {applicationPeriod.occasionsFrom.toLocaleDateString()}{" "}
                        till {applicationPeriod.occasionsTo.toLocaleDateString()}
                    </h2>
                </header>
                <InfoMessage message="Det finns inga evenemang från urvalet att visa." />
            </>
        );
    }

    const filterChangeHandler = (updatedProperty: keyof ApplicationPlanningFilterOptions, updatedValue: any) => {
        setSelectedFilterOptions((prevValue) => ({ ...prevValue, [updatedProperty]: updatedValue }));
    };

    const filteredApplications = applyApplicationPlanningFilter(
        applications,
        plannedApplicationSites,
        selectedFilterOptions
    );

    const applicationTableRows = filteredApplications.map((a) => (
        <ApplicationTableRow application={a} plannedApplicationSites={plannedApplicationSites} key={a.id} />
    ));

    return (
        <div className="container mt-2">
            <header className="d-flex justify-content-between">
                <h2>
                    Programläggning - evenemang: spelperiod {applicationPeriod.occasionsFrom.toLocaleDateString()} till{" "}
                    {applicationPeriod.occasionsTo.toLocaleDateString()}
                </h2>
            </header>

            <Accordion>
                <PlanningOverviewAccordionItem
                    plannedApplicationSites={plannedApplicationSites}
                    planningComments={planningComments}
                    applicationPeriod={applicationPeriod}
                    showExport={true}
                />
            </Accordion>

            <ApplicationPlanningFilter
                selectedFilterOptions={selectedFilterOptions}
                onSelectedFilterOptionsChange={filterChangeHandler}
            />
            <div className="border rounded">
                <table className="table table-hover-effect ">
                    <thead>
                        <tr>
                            <th>Aktör</th>
                            <th>Evenemangsnamn</th>
                            <th>Målgrupp</th>
                            <th style={{ width: "175px" }}>Tillfällen totalt</th>
                            <th style={{ width: "175px" }}>Ej Programlagda</th>
                        </tr>
                    </thead>
                    <tbody>{applicationTableRows}</tbody>
                </table>
            </div>
        </div>
    );
}

export default ApplicationPlanning;
