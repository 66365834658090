import React from "react";

export type SiteApplicationSelectionFilterOptions = {
    searchText: string;
};

type SiteApplicationSelectionFilterProps = {
    selectedFilterOptions: SiteApplicationSelectionFilterOptions;
    onSelectedFilterOptionsChange: (
        updatedProperty: keyof SiteApplicationSelectionFilterOptions,
        updatedValue: any
    ) => void;
};

function SiteApplicationSelectionFilter({
    selectedFilterOptions,
    onSelectedFilterOptionsChange
}: SiteApplicationSelectionFilterProps) {
    return (
        <div className="row mb-2">
            <div className="col-md-3 form-group">
                <label className="form-label mb-0" htmlFor="search-input">
                    Fritextsök
                </label>
                <input
                    className="form-control"
                    type="text"
                    id="search-input"
                    placeholder="Sök på namn eller aktör"
                    value={selectedFilterOptions ? selectedFilterOptions.searchText : ""}
                    onChange={(event) => onSelectedFilterOptionsChange("searchText", event.target.value)}
                />
            </div>
        </div>
    );
}

export default SiteApplicationSelectionFilter;
