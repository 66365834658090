import React, { CSSProperties, ChangeEvent, useEffect, useRef, useState } from "react";
import CultureProvider from "../../types/CultureProvider";
import PlannedApplicationSite from "../../types/PlannedApplicationSite";
import {
    fetchApplicationPeriod,
    fetchApplicationsForActorContractCreation,
    fetchCultureProviderForContractCreation,
    fetchPlannedApplicationSitesForActorContract,
    fetchSitesByApplicationPeriodId
} from "../../common/apiClient";
import { pageLoadErrorMessage } from "../../common/errorMessages";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import LoadingSpinner from "../Common/LoadingSpinner";
import ErrorMessage from "../Common/Messages/ErrorMessage";
import Application from "../../types/Application";
import { getTotalCostForPlannedApplicationSites } from "../../common/applicationUtils";
import OccasionScheduleTableRow from "./OccasionScheduleTableRow";
import { sum } from "../../common/numberUtils";
import SuccessMessage from "../Common/Messages/SuccessMessage";
import { copyRichText } from "../../common/stringUtils";
import { defaultMarginBottom, defaultTextStyles, tableCellStyles, tableStyles } from "../../common/inlineStyles";

type ActorContractCreationProps = {
    applicationPeriodId: number;
    cultureProviderId: number;
};

function ActorContractCreation({ applicationPeriodId, cultureProviderId }: ActorContractCreationProps) {
    const [cultureProvider, setCultureProvider] = useState<CultureProvider>();
    const [plannedApplicationSites, setPlannedApplicationSites] = useState<PlannedApplicationSite[]>([]);
    const [applications, setApplications] = useState<Application[]>([]);
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [representedByInput, setRepresentedByInput] = useState("");

    const contractRef = useRef<HTMLDivElement>();
    const successMessageRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const fetchData = async () => {
            const [
                cultureProviderFetchResult,
                plannedApplicationSitesFetchResult,
                applicationPeriodFetchResult,
                applicationsFetchResult,
                sitesFetchResult
            ] = await Promise.all([
                fetchCultureProviderForContractCreation(applicationPeriodId, cultureProviderId),
                fetchPlannedApplicationSitesForActorContract(cultureProviderId, applicationPeriodId),
                fetchApplicationPeriod(applicationPeriodId),
                fetchApplicationsForActorContractCreation(applicationPeriodId, cultureProviderId),
                fetchSitesByApplicationPeriodId(applicationPeriodId)
            ]);

            if (cultureProviderFetchResult.errorMessage) {
                console.error(cultureProviderFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (plannedApplicationSitesFetchResult.errorMessage) {
                console.error(plannedApplicationSitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationsFetchResult.errorMessage) {
                console.error(applicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (sitesFetchResult.errorMessage) {
                console.error(sitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            plannedApplicationSitesFetchResult.value.sort(
                (a, b) => a.occasion1Starts.getTime() - b.occasion1Starts.getTime()
            );

            plannedApplicationSitesFetchResult.value.forEach((pas) => {
                const application = applicationsFetchResult.value.find((a) => a.id === pas.applicationId);
                const site = sitesFetchResult.value.find((s) => s.id === pas.siteId);

                if (!application) {
                    console.error(`Ingen intresseanmälan med id ${pas.applicationId} skickades från backend.`);
                    setErrorMessage(pageLoadErrorMessage);
                    return;
                }

                if (!site) {
                    console.error(`Ingen spelplats med id ${pas.siteId} skickades från backend.`);
                    setErrorMessage(pageLoadErrorMessage);
                    return;
                }

                pas.application = application;
                pas.site = site;
            });

            setCultureProvider(cultureProviderFetchResult.value);
            setPlannedApplicationSites(plannedApplicationSitesFetchResult.value);
            setApplicationPeriod(applicationPeriodFetchResult.value);
            setApplications(applicationsFetchResult.value);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    const copyToClipboardClickHandler = async () => {
        await copyRichText(contractRef.current.innerHTML);

        if (!copiedToClipboard) {
            setCopiedToClipboard(true);
            setTimeout(() => {
                successMessageRef.current.classList.add("slide-out");
                setTimeout(() => {
                    setCopiedToClipboard(false);
                }, 80);
            }, 3000);
        }
    };

    const occasionScheduleTableRows = plannedApplicationSites.map((pas) => (
        <OccasionScheduleTableRow key={pas.id} plannedApplicationSite={pas} />
    ));

    const disableCopyToClipboard = !representedByInput;

    const numberOfProductions = new Set(plannedApplicationSites.map((pas) => pas.applicationId)).size;

    const totalNumberOfPlannedOccasions = sum(applications.map((a) => a.numberOfPlannedOccasions));

    const totalCost = sum(applications.map((a) => getTotalCostForPlannedApplicationSites(a, plannedApplicationSites)));

    return (
        <div className="mt-3">
            {copiedToClipboard ? (
                <div className="slide-alert" ref={successMessageRef}>
                    <SuccessMessage message="Avtalsunderlaget har kopierats!" />{" "}
                </div>
            ) : (
                <></>
            )}
            <h2 className="d-flex justify-content-center">
                Avtalsunderlag: {cultureProvider.name}, spelperiod{" "}
                {applicationPeriod.occasionsFrom.toLocaleDateString()}
            </h2>
            <div style={{ width: "800px", margin: "auto" }}>
                <div className="row mb-2">
                    <div className="col-md-6">
                        <label className="form-label">Uppdragsgivare representerad av</label>
                        <input
                            className="form-control"
                            type="text"
                            value={representedByInput}
                            onChange={(e) => setRepresentedByInput(e.target.value)}
                        ></input>
                    </div>
                </div>

                <button
                    className="btn btn-primary mb-2"
                    onClick={copyToClipboardClickHandler}
                    disabled={disableCopyToClipboard}
                >
                    <i className="bi bi-clipboard me-1"></i>
                    Kopiera till urklipp
                </button>

                <div className="border rounded p-5" ref={contractRef}>
                    <h2 style={{ marginBottom: defaultMarginBottom }}>Avtal</h2>
                    <div style={{ ...defaultTextStyles, ...{ marginBottom: "12px" } }}>Mellan</div>
                    <div style={{ ...defaultTextStyles, ...{ marginBottom: "12px" } }}>
                        <span style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>Uppdragsgivare</span> <br />
                        Göteborgs Stads kulturförvaltning <br />
                        Östra Hamngatan 16 <br />
                        411 09 Göteborg <br />
                        Representerad av: {representedByInput} <br />
                        (nedan kallad KuBo) <br />
                        Org. Nr: 212000-1355 <br />
                        Kontaktperson: Victoria Alarik <br />
                        E-post:{" "}
                        <a href="mailto:victoria.alarik@kultur.goteborg.se">victoria.alarik@kultur.goteborg.se</a>{" "}
                        <br />
                        Tel 031- 368 34 93 | Mob 0703-43 30 49
                    </div>
                    <div style={{ ...defaultTextStyles, ...{ marginBottom: "12px" } }}>Och</div>
                    <div style={defaultTextStyles}>
                        <span style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>Uppdragstagare</span> <br />
                        {cultureProvider.organization} <br />
                        {cultureProvider.address} <br />
                        {cultureProvider.postalCode} {cultureProvider.postalAddress} <br />
                        (nedan kallad Aktören) <br />
                        Org. Nr: {cultureProvider.organizationNumber} <br />
                        Kontaktperson: {cultureProvider.contactName} <br />
                        E-post: <a href={`mailto:${cultureProvider.emailAddress}`}>
                            {cultureProvider.emailAddress}
                        </a>{" "}
                        <br />
                        Tel {cultureProvider.phoneNumber}
                    </div>
                    <p style={defaultTextStyles}>
                        har härmed följande avtal träffats rörande uppdraget i punkt 1. <br />
                        Ovan nämnda uppdragstagare äger rätt att representera alla medverkande artister.
                    </p>
                    <div style={{ textAlign: "center" }}>________________________________</div>

                    <p style={defaultTextStyles}>
                        KuBo är ett samarbete inom Göteborgs stad mellan kulturförvaltningen, grundskoleförvaltningen
                        och förskoleförvaltningen, som finansieras med offentliga medel. Målet är att fördela ett
                        kvalitativt och brett kulturutbud mer jämlikt över staden. KuBo samlar kulturutbudet på en
                        bokningssida för kommunala och fristående förskolor, grundskolor och gymnasier i Göteborg.
                        Lärare och pedagoger kan boka in sig på aktiviteter för sina klasser och förskolor. Det nya
                        systemet ger möjligheten att fördela utbudet över staden, sammanställa statistik över
                        deltagandet och skapa riktade erbjudanden till specifika områden eller skolor. Du hittar KuBo på
                        kubo.goteborg.se
                    </p>

                    <p style={defaultTextStyles}>
                        I detta avtal är det Kulturförvaltningen som är part i förhållande till Aktören.
                    </p>

                    <ol>
                        <li style={defaultTextStyles}>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>Uppdrag</div>
                            Aktören ska inom spelperioden {applicationPeriod.occasionsFrom.toLocaleDateString()} -{" "}
                            {applicationPeriod.occasionsTo.toLocaleDateString()} på uppdrag av KuBo utföra följande:{" "}
                            <br />
                            <br /> {numberOfProductions} produktion(er) med {totalNumberOfPlannedOccasions}{" "}
                            speltillfällen
                        </li>
                        <li style={defaultTextStyles}>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>
                                Spelplan som Aktören förbinder sig att följa
                            </div>
                            <p style={defaultTextStyles}>
                                Spelplan enligt nedan med spelplats, datum, tider, antal publikplatser och målgrupp har
                                kommunicerats via e-post samt finns publicerat på kubo.goteborg.se.
                            </p>
                            <div style={{ marginLeft: 0 }}>
                                <table style={{ ...tableStyles, fontSize: "10pt" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ ...tableCellStyles, width: "70px" }}>Plats</th>
                                            <th style={{ ...tableCellStyles, width: "70px" }}>Lokal</th>
                                            <th style={{ ...tableCellStyles, width: "36px" }}>Platser</th>
                                            <th style={{ ...tableCellStyles, width: "50px" }}>Datum</th>
                                            <th style={{ ...tableCellStyles, width: "30px" }}>Vecka</th>
                                            <th style={{ ...tableCellStyles, width: "46px" }}>Veckodag</th>
                                            <th style={{ ...tableCellStyles, width: "32px" }}>Tid 1</th>
                                            <th style={{ ...tableCellStyles, width: "32px" }}>Tid 2</th>
                                            <th style={{ ...tableCellStyles, width: "60px" }}>Föreställning</th>
                                            <th style={{ ...tableCellStyles, width: "54px" }}>Målgrupp</th>
                                            <th style={{ ...tableCellStyles, width: "54px" }}>
                                                Spelplatsens kontaktuppgifter
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{occasionScheduleTableRows}</tbody>
                                </table>
                                <br />
                            </div>
                            <p style={defaultTextStyles}>
                                I de fall uppsökande föreställningar programläggs på spelplatser i staden, regleras
                                dessa åtaganden i särskilt avtal mellan kulturförvaltningen och respektive spelplats.
                                Föreställningens behov av teknik och lokal beaktas i dessa.
                            </p>
                            <p style={defaultTextStyles}>
                                Aktören ska ta kontakt med uppsökande spelplats i god tid före framförandet eller med
                                kontaktperson/lärare vid uppsökande i förskola/skola efter bokningen är genomförd, för
                                detaljplanering av arrangemanget. Lista med kontakter till spelplatser tillhandahålles
                                Aktören av KuBo.
                            </p>
                        </li>
                        <li style={defaultTextStyles}>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>
                                Information, marknadsföring och bokning
                            </div>
                            <p style={defaultTextStyles}>
                                Detta avtal ger KuBo rätt att marknadsföra aktören enligt kulturförvaltningens,
                                förskoleförvaltningens, grundskoleförvaltningens och utbildningsförvaltningens ordinarie
                                rutiner och marknadsföringskanaler.
                            </p>
                            <p style={defaultTextStyles}>
                                KuBo ansvarar för information och marknadsföring inom ordinarie kanaler såsom
                                nyhetsbrev, webb samt viss annonsering, med sådant material och på sådant sätt som KuBo
                                finner lämpligt.
                            </p>
                            <p style={defaultTextStyles}>
                                KuBo förbehåller sig rätten att anpassa informations- och marknadsföringsmaterial i
                                enlighet med gällande grafisk profil och bokningssida.
                            </p>
                            <p style={defaultTextStyles}>
                                Så snart parterna träffat en överenskommelse om evenemanget ska aktören ha
                                tillhandahållit informations- och marknadsföringsmaterial, inklusive högupplösta foton
                                och filmer. Detta ska ske på anmodan av KuBo och senast vid undertecknande av detta
                                avtal.
                            </p>

                            <p style={defaultTextStyles}>
                                KuBo hanterar all bokning via bokningssidan på{" "}
                                <a href="https://kubo.goteborg.se/">kubo.goteborg.se</a>. KuBo beslutar om datum för
                                publicering samt fördelning och bokning av målgrupper. Bokningsinformation kommer att
                                vara tillgängliga i systemet även för aktörer.
                            </p>
                        </li>
                        <li>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>Personuppgifter</div>
                            <p style={defaultTextStyles}>
                                Aktören garanterar att samtliga medverkande lämnat sitt samtycke till att KuBo får lagra
                                information och personuppgifter som är nödvändiga för att genomföra produktionen.
                            </p>
                            <p style={defaultTextStyles}>
                                KuBo äger rätt att publicera personuppgifter såsom namn och bilder i informations- och
                                marknadsföringssyfte på samtliga medverkande artister i samtliga ordinarie
                                marknadsföringskanaler.
                            </p>
                            <p style={defaultTextStyles}>
                                Hanteringen av personuppgifter sker i enlighet med verksamhetens plan (Göteborgs stads
                                kulturförvaltning) för hantering av personuppgifter enligt GDPR.
                            </p>
                        </li>
                        <li>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>Aktörens åtaganden</div>
                            <div style={{ ...defaultTextStyles, ...{ textDecoration: "underline" } }}>
                                Generella villkor
                            </div>
                            <p style={defaultTextStyles}>
                                Aktörens rättigheter och/eller skyldigheter enligt detta avtal får inte överlåtas till
                                annan utan KuBo i förtid skriftliga medgivande.
                            </p>
                            <p style={defaultTextStyles}>
                                Aktören garanterar att andra avtal inte hindrar eller stör åtagandet enligt detta avtal.
                            </p>
                            <p style={defaultTextStyles}>
                                Aktören ansvarar för alla upphovsrättigheter som är förknippade med evenemanget och
                                svarar för de kostnader som uppstår för dessa.{" "}
                            </p>
                            <div style={{ ...defaultTextStyles, ...{ textDecoration: "underline" } }}>
                                För aktörer med produktioner på fasta scener eller i egen regi
                            </div>
                            <ul style={{ marginBottom: defaultMarginBottom }}>
                                <li style={defaultTextStyles}>
                                    Ansvara för att facilitera arrangemanget och bistå med personal
                                </li>
                                <li style={defaultTextStyles}>Ta emot barn och elever</li>
                                <li style={defaultTextStyles}>
                                    Ansvara för brandsäkerhet, sjukvårdsberedskap och utrymning
                                </li>
                                <li style={defaultTextStyles}>Ansvara för all befintlig teknik och säkerhet</li>
                                <li style={defaultTextStyles}>
                                    Ha rollen som Aktör och Värd i KuBos bokningssida, d v s ha kontakt med bokade
                                    grupper samt registrera närvaro i efterhand
                                </li>
                            </ul>

                            <div style={{ ...defaultTextStyles, ...{ textDecoration: "underline" } }}>Exklusivitet</div>
                            <p style={defaultTextStyles}>
                                Aktören är vid sjukdomsfall skyldig att omedelbart meddela KuBo. Vid avbokning på grund
                                av sjukdom gäller vad som sägs i punkt 8.
                            </p>
                            <p style={defaultTextStyles}>
                                Aktören ska skyndsamt meddela KuBo om en väsentlig förändring av arrangemanget planeras.
                            </p>

                            <div style={{ ...defaultTextStyles, ...{ textDecoration: "underline" } }}>
                                Teknik och säkerhet vid uppsökande spelplatser
                            </div>
                            <p style={defaultTextStyles}>
                                Aktören ansvarar för all egen personal och egen utrustning och att säkerhetskraven är
                                uppfyllda inför varje föreställning. Aktören är skyldig att ha erforderliga försäkringar
                                för stöld, sak- och personskador.{" "}
                            </p>

                            <p style={defaultTextStyles}>
                                För övriga skyldigheter gällande teknik och säkerhet, se Bilaga 1, till detta dokument.
                            </p>

                            <div style={{ ...defaultTextStyles, ...{ textDecoration: "underline" } }}>
                                Likabehandling och diskriminering
                            </div>
                            <p style={defaultTextStyles}>
                                Aktörens personal förbinder sig att följa Göteborgs stads policy om likabehandling,
                                vilket inkluderar nolltolerans mot all form av diskriminering och trakasserier, sexuella
                                såväl som andra. Hets mot folkgrupp eller andra olagliga handlingar får inte förekomma.
                            </p>
                            <p style={defaultTextStyles}>
                                Aktörens rättigheter och/eller skyldigheter enligt detta avtal får inte överlåtas till
                                annan utan KuBos skriftliga medgivande.
                            </p>
                        </li>
                        <li>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>
                                Uppdragsgivarens åtaganden
                            </div>

                            <div style={{ ...defaultTextStyles, ...{ textDecoration: "underline" } }}>
                                För samtliga aktörer:
                            </div>
                            <ul style={{ marginBottom: defaultMarginBottom }}>
                                <li style={defaultTextStyles}>
                                    Administration och hantering av bokningssida samt uppföljning och statistik
                                </li>
                                <li style={defaultTextStyles}>Tillhandahålla bokningsinformation till Aktören</li>
                                <li style={defaultTextStyles}>
                                    Publikarbete riktat till förskolor och skolor genom bokningssidan KuBo
                                </li>
                                <li style={defaultTextStyles}>
                                    Kommunikation och marknadsföring till förskolor och skolor
                                </li>
                            </ul>

                            <div style={{ ...defaultTextStyles, ...{ textDecoration: "underline" } }}>
                                För aktörer med uppsökande produktioner i skola eller på spelplatse:
                            </div>
                            <ul style={{ marginBottom: defaultMarginBottom }}>
                                <li style={defaultTextStyles}>Ansvar för programläggning på fasta spelplatser</li>
                                <li style={defaultTextStyles}>
                                    <div style={defaultTextStyles}>
                                        Ansvar för facilitering av arrangemanget genom att vara:
                                    </div>
                                    <ul>
                                        <li style={defaultTextStyles}>Publikvärd, ta emot barn och elever</li>
                                        <li style={defaultTextStyles}>Artistvärd, ta emot kulturaktörer</li>
                                    </ul>
                                </li>
                                <li style={defaultTextStyles}>
                                    Ansvar för brandsäkerhet, sjukvårdsberedskap och utrymning
                                </li>
                                <li style={defaultTextStyles}>Ansvar för all befintlig teknik och säkerhet</li>
                            </ul>

                            <p style={defaultTextStyles}>
                                KuBo är skyldig att omedelbart meddela Aktören och hålla Aktören uppdaterad om
                                eventuella förändringar i tidplan eller plats för uppdraget.
                            </p>

                            <p style={defaultTextStyles}>
                                KuBo lägger stor vikt vid det värdskap med vilket vi möter och tar hand om de aktörer
                                som kommer till oss. Vi gör vårt yttersta för att möta de önskningar som kommer till oss
                                från aktörer och dess representanter. Som en del av Göteborgs Stad vill vi bidra till en
                                god arbetsmiljö för aktörer, spelplatsens personal samt andra berörda, samt att på ett
                                allmänt sätt bidra till hälsa och hållbarhet inom branschen.
                            </p>
                        </li>
                        <li>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>Ersättning</div>
                            <p style={defaultTextStyles}>
                                KuBo ska till Aktören utge ett gage om {totalCost.toLocaleString()} kr (exklusive moms)
                                samt ersättning för transport/frakt. Ersättningen omfattar:
                            </p>

                            <ul>
                                <li>
                                    <span style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>
                                        Uppdraget enligt punkt 1
                                    </span>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={tableCellStyles}>Föreställning</th>
                                                    <th style={tableCellStyles}>Antal fst</th>
                                                    <th style={tableCellStyles}>Totalt inköp SEK</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {applications.map((a) => (
                                                    <tr key={a.id}>
                                                        <td style={tableCellStyles}>{a.document.eventName}</td>
                                                        <td
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...{ textAlign: "end" }
                                                            }}
                                                        >
                                                            {a.numberOfPlannedOccasions}
                                                        </td>
                                                        <td
                                                            style={{
                                                                ...tableCellStyles,
                                                                ...{ textAlign: "end" }
                                                            }}
                                                        >
                                                            {getTotalCostForPlannedApplicationSites(
                                                                a,
                                                                plannedApplicationSites
                                                            ).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </li>
                                <li>
                                    <div style={defaultTextStyles}>
                                        <span style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>
                                            Ersättning för transport/frakt vid behov
                                        </span>
                                        (ej enbart personresa):
                                    </div>
                                    <p style={defaultTextStyles}>
                                        Ange milersättning: vid användning av privat bil ange max 30 kr/mil och vid
                                        användning av tjänstebil ange max 60 kr/mil. Vid annan typ av transport
                                        och/eller för övriga kostnader i samband med transport specificera faktiska
                                        utgifter.
                                    </p>
                                </li>
                            </ul>
                            <p style={defaultTextStyles}>
                                Ersättning utbetalas mot E-faktura, via nätverket PEPPOL, VAN-operatör eller via
                                Göteborgs Stads fakturaportal där du manuellt kan registrera en e-faktura: <br />
                                <a href="https://goteborg.se/wps/portal/start/foretag-och-organisationer/upphandling-och-inkop/for-leverantorer-till-goteborgs-stad/fakturera-goteborgs-stad">
                                    www.goteborg.se/fakturera
                                </a>
                            </p>

                            <p style={defaultTextStyles}>
                                E-fakturaadress: <br />
                                N690 Kulturförvaltningen <br />
                                GLN: 7381035001594 <br />
                                ORG NR: 212000-1355
                            </p>

                            <p style={defaultTextStyles}>REFERENS, ange i fakturan: 3260</p>

                            <p style={defaultTextStyles}>
                                Betalning sker med betalningsvillkor om trettio (30) dagar från mottagandet av fakturan,
                                om inte annat överenskommits särskilt. Fakturan måste uppfylla alla krav som anges i
                                denna punkt för att vara förfallen till betalning. Fakturering ska ske skyndsamt efter
                                att uppdraget genomförts och delfaktureras efter varje avslutad månad. Fakturor inkomna
                                senare än 6 månader efter genomfört evenemang kommer inte att betalas ut.
                            </p>
                            <div style={{ textAlign: "center" }}>________________________________</div>
                        </li>
                        <li>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>
                                Avtalsbrott och hävning
                            </div>
                            <p style={defaultTextStyles}>
                                Part har rätt att med omedelbar verkan häva detta avtal om andre parten gör sig skyldig
                                till avtalsbrott som inte är oväsentligt och rättelse inte har skett inom [10] dagar
                                efter skriftlig varning. Hävande part har rätt att erhålla ersättning för direkt skada
                                som uppkommit i anledning av hävningen.
                            </p>

                            <p style={defaultTextStyles}>
                                Parterna ska vara befriade från att fullgöra skyldighet enligt avtalet om utförande
                                hindras eller oskäligt betungas till följd av händelser utanför partens kontroll och som
                                inte hade kunnat förutses (force majeure). Såsom sådan befriande omständighet ska anses
                                bl.a. myndighetsåtgärd eller underlåtenhet, nytillkommen eller ändrad lagstiftning,
                                blockad, brand, översvämning eller olyckshändelse av större omfattning.
                            </p>

                            <p style={defaultTextStyles}>
                                Skulle Aktören på grund av sjukdom inte kunna genomföra uppdraget, upphör avtalet
                                angående aktuellt uppdrag att gälla. Skulle någon av de personer som anges i punkt 1
                                inte kunna medverka på grund av sjukdom men Aktören ändå anser sig kunna genomföra
                                uppdraget med kvarvarande personer eller ersättare, alternativt flytta fram evenemanget
                                inom tiden angiven i punkten 2, har KuBo inte rätt att göra avdrag på den avtalade
                                ersättningen eller på annat sätt kräva ersättning från Aktören. Om det inte är möjligt
                                kan parterna i det enskilda fallet komma överens om att föreställningen ska genomföras
                                på annat sätt, såsom digitalt.
                                <br />
                                Om inga av dessa alternativ är möjliga kan föreställningen helt ställas in och
                                ersättning ändå betalas ut, dock på begäran kunna styrkas med läkarintyg.
                            </p>

                            <p style={defaultTextStyles}>
                                Vid utebliven publik eller obokat evenemang, kommer ersättning ändock betalas ut.
                            </p>

                            <p style={defaultTextStyles}>
                                Skulle någon av parterna av annan orsak än som angivits ovan inte uppfylla sina
                                förpliktelser enligt detta avtal, ska avtalsbrytande part vara förpliktad att ersätta
                                den andra parten för den skada som den andra parten drabbas av genom avtalsbrottet.
                            </p>
                        </li>
                        <li>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>Ändringar och tillägg</div>
                            <p style={defaultTextStyles}>
                                Samtliga ändringar och tillägg till detta avtal ska göras skriftligen och undertecknas
                                av båda parter för att vara gällande.
                            </p>
                        </li>
                        <li>
                            <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>Tvistelösning</div>
                            <p style={defaultTextStyles}>
                                Parterna ska verka för en lösning i samförstånd av frågor som uppkommer i samband med
                                detta avtal för att möjliggöra syftet med detta avtal. I den händelse att tvist uppstår
                                i anledning av avtalet, som inte kan lösas genom överläggningar mellan parterna, ska
                                tvisten prövas i enlighet med gällande lagstiftning och av Göteborgs tingsrätt.
                            </p>
                        </li>
                    </ol>
                    <div style={{ textAlign: "center" }}>
                        __________________________________________________________________________________
                    </div>

                    <p style={defaultTextStyles}>
                        Avtalet har signerats av Kulturförvaltningen i Göteborg och avtalspart genom digital signering.
                    </p>

                    <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>Underskrifter</div>
                    <p style={defaultTextStyles}>Digitala signaturer återfinns på sista sida.</p>

                    <p style={defaultTextStyles}>{representedByInput}</p>

                    <div>
                        <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>BILAGA 1</div>
                        <div style={{ ...defaultTextStyles, ...{ fontWeight: "bold" } }}>
                            Allmänna förhållningsregler gällande teknik och säkerhet vid uppsökande spelplatser
                        </div>

                        <p style={defaultTextStyles}>
                            Innehåller föreställningen moment med rökmaskin eller liknande skall detta anmälas 6 veckor
                            innan ankomst.
                        </p>

                        <p style={defaultTextStyles}>
                            Innehåller föreställningen moment med pyroteknik skall Aktören ansvara för att erforderliga
                            tillstånd innehas vid ankomst samt följa spelplatsens ramtillstånd om möjlighet att förvara
                            pyroteknik (t ex av klass 1.3 och 1.4). Användandet av pyroteknik skall anmälas till
                            spelplatsen senast 10 veckor innan ankomst.
                        </p>

                        <p style={defaultTextStyles}>
                            Eld och öppen låga under föreställningen kräver brandvakt i form av utbildad personal.
                            Användandet av eld eller öppen låga skall anmälas till spelplatsen senast 6 veckor innan
                            ankomst.
                        </p>

                        <p style={defaultTextStyles}>
                            Laser med max 5mW uteffekt får användas utan särskilt tillstånd från
                            Strålsäkerhetsmyndigheten. Användandet av laser skall anmälas till spelplatsen senast 6
                            veckor innan ankomst.
                        </p>

                        <p style={defaultTextStyles}>
                            Aktören är skyldig att följa de regler vad gäller ljudnivåer fastställda av
                            Miljöförvaltningen i Göteborg. Maximal ljudnivå om inget annat överenskommits är satt till
                            97db i medel och 110db i max.
                        </p>

                        <p style={defaultTextStyles}>
                            Grovsopor och emballage skall medtagas och lokalen skall grovstädas och återställas till
                            samma skick som vid ankomst.
                        </p>

                        <p style={defaultTextStyles}>
                            Inget material får kvarlämnas i lokalen efter avtalstidens slut om inte annat avtalats.
                        </p>

                        <p style={defaultTextStyles}>
                            Aktören ansvarar för att dekor, scen/ljud/ljusteknik och scentyger som medtages är
                            säkerhetsmässigt konstruerade enligt gällande normer.
                        </p>

                        <p style={defaultTextStyles}>
                            Inhyrd teknik som spelplatsen tillhandahåller åt Aktören skall vara definierad av denne
                            senast 6 veckor innan ankomst.
                        </p>

                        <p style={defaultTextStyles}>
                            Spelplatsens intäckningar, scen/ljus/ljudutrustning och scenmattor skall efter användande
                            återställas till ursprungsposition och skick.
                        </p>

                        <p style={defaultTextStyles}>
                            Aktören och aktörens personal måste följa de säkerhets- och ordningsregler som upprättats på
                            engagemangsplatsen.
                        </p>

                        <p style={defaultTextStyles}>
                            Aktören är skyldig att ersätta spelplatsen för skador som orsakas genom försummelse eller
                            vårdslöshet av någon som Aktören svarar för.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ActorContractCreation;
