import React, { CSSProperties, useEffect } from "react";
import { AgeTag, SiteType, Tag, TargetGroup } from "../../common/enums";
import Application from "../../types/Application";
import ApplicationPeriod from "../../types/ApplicationPeriod";

export type SelectedFilterOptions = {
    actorName: string;
    tag: Tag;
    ageTag: AgeTag;
    siteType: SiteType;
    showHiddenApplications: boolean;
};

type FilterProps = {
    applications: Application[];
    applicationPeriod: ApplicationPeriod;
    onSelectedFilterOptionsChange: (updatedProperty: keyof SelectedFilterOptions, updatedValue: any) => void;
    selectedFilterOptions: SelectedFilterOptions;
    targetGroup: TargetGroup;
    isCulturalManagement: boolean;
};

const tagEntries = Object.entries(Tag);

const ageTagEntries = Object.entries(AgeTag).filter((entry) => entry[1] !== AgeTag.SpecialNeedsSchoolOld);

const siteTypeEntries = Object.entries(SiteType);

function Filter({
    applications,
    applicationPeriod,
    onSelectedFilterOptionsChange,
    selectedFilterOptions,
    targetGroup,
    isCulturalManagement
}: FilterProps) {
    const actorOptions = applications
        .map((a) => a.actorName)
        .filter((value, index, self) => self.indexOf(value) === index); // This ensures all elements are unique.

    useEffect(() => {
        if (
            selectedFilterOptions &&
            selectedFilterOptions.actorName &&
            !actorOptions.includes(selectedFilterOptions.actorName)
        ) {
            onSelectedFilterOptionsChange("actorName", "");
        }
    }, [applications, selectedFilterOptions]);

    const excelExportUrl = `/api/applicationPeriod/${applicationPeriod.id}/selection/export?${new URLSearchParams(
        { ...selectedFilterOptions, showHiddenApplications: "" } ?? {}
    ).toString()}`;

    const dropdownWidth = targetGroup === TargetGroup.Total && !isCulturalManagement ? "21%" : "18%";

    return (
        <div className="d-flex justify-content-between ms-4 me-4 ps-1 pe-1 ">
            <div className="form-group" style={{ width: dropdownWidth }}>
                <label className="form-label m-0">Aktör</label>
                <select
                    className="form-select"
                    value={selectedFilterOptions ? selectedFilterOptions.actorName : ""}
                    onChange={(event) => onSelectedFilterOptionsChange("actorName", event.target.value)}
                >
                    <option value={""}>- Alla aktörer -</option>
                    {actorOptions.map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group" style={{ width: dropdownWidth }}>
                <label className="form-label m-0">Ålder</label>
                <select
                    className="form-select"
                    value={selectedFilterOptions ? selectedFilterOptions.ageTag : ""}
                    onChange={(event) => onSelectedFilterOptionsChange("ageTag", event.target.value)}
                >
                    <option value={""}>- Alla åldrar -</option>
                    {ageTagEntries.map((entry) => (
                        <option key={entry[1]} value={entry[1]}>
                            {entry[1]}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group" style={{ width: dropdownWidth }}>
                <label className="form-label m-0">Spelplats</label>
                <select
                    className="form-select"
                    value={selectedFilterOptions ? selectedFilterOptions.siteType : ""}
                    onChange={(event) => onSelectedFilterOptionsChange("siteType", event.target.value)}
                >
                    <option value={""}>- Alla spelplatser -</option>
                    {siteTypeEntries.map((entry) => (
                        <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group" style={{ width: dropdownWidth }}>
                <label className="form-label m-0">Konstform</label>
                <select
                    className="form-select"
                    value={selectedFilterOptions ? selectedFilterOptions.tag : ""}
                    onChange={(event) => onSelectedFilterOptionsChange("tag", event.target.value)}
                >
                    <option value={""}>- Alla konstformer -</option>
                    {tagEntries.map((entry) => (
                        <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                        </option>
                    ))}
                </select>
            </div>
            {targetGroup !== TargetGroup.Total && (
                <div className="form-group" style={{ width: dropdownWidth }}>
                    <label className="form-label m-0">Dold</label>
                    <select
                        className="form-select"
                        value={selectedFilterOptions ? String(selectedFilterOptions.showHiddenApplications) : "false"}
                        onChange={(event) =>
                            onSelectedFilterOptionsChange("showHiddenApplications", event.target.value === "true")
                        }
                    >
                        <option value={"false"}>Visa inte dolda intresseanmälningar</option>
                        <option value={"true"}>Visa dolda intresseanmälningar</option>
                    </select>
                </div>
            )}
            {targetGroup === TargetGroup.Total && !isCulturalManagement && (
                <div className="d-flex align-items-end">
                    <a className="btn btn-success" href={excelExportUrl}>
                        <i className="bi bi-file-excel-fill"></i>
                        Ladda ner excelfil
                    </a>
                </div>
            )}
        </div>
    );
}

export default Filter;
