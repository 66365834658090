import React, { ChangeEvent, useEffect, useState } from "react";
import Application from "../../types/Application";
import { TargetGroup } from "../../common/enums";
import { getSelectionCommentByTargetGroup } from "../../common/applicationUtils";
import { Modal } from "react-bootstrap";
import InfoMessage from "../Common/Messages/InfoMessage";
import SelectionComment from "../../types/SelectionComment";
import { postSelectionComment } from "../../common/apiClient";

type SelectionCommentModalProps = {
    application: Application;
    show: boolean;
    onHide: () => void;
    onApplicationChange: (application: Application) => void;
    onError: (errorMessage: string) => void;
    targetGroup: TargetGroup;
    isCulturalManagement: boolean;
};

function SelectionCommentModal({
    application,
    show,
    onHide,
    onApplicationChange,
    onError,
    isCulturalManagement,
    targetGroup
}: SelectionCommentModalProps) {
    const [comment, setComment] = useState<string>("");
    const [commentIsSaved, setCommentIsSaved] = useState(false);

    useEffect(() => {
        const commentByTargetGroup = getSelectionCommentByTargetGroup(application, targetGroup, isCulturalManagement);

        setComment(commentByTargetGroup);
    }, [application, targetGroup, isCulturalManagement]);

    const commentChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
        setCommentIsSaved(false);
    };

    const saveButtonClickHandler = async () => {
        setCommentIsSaved(true);

        const selectionComment: SelectionComment = {
            applicationId: application.id,
            targetGroup: targetGroup,
            isCulturalManagement: isCulturalManagement,
            comment: comment
        };

        const updatedSelectionComments = [
            ...application.selectionComments.filter(
                (sc) => sc.targetGroup !== targetGroup || sc.isCulturalManagement !== isCulturalManagement
            ),
            selectionComment
        ];

        const updatedApplication: Application = { ...application, selectionComments: updatedSelectionComments };

        onApplicationChange(updatedApplication);

        const postResult = await postSelectionComment(selectionComment);

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            onError("Något gick fel när kommentaren sparades.");
            return;
        }

        setCommentIsSaved(true);
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <h4>Kommentar om "{application.document.eventName}"</h4>
            </Modal.Header>
            <Modal.Body>
                <InfoMessage
                    message={`
                        Här kan du lägga till en kommentar om evenemanget "${application.document.eventName}" av ${application.actorName}. 
                        Kommentaren är bunden till det nuvarande budgetalternativet och kan ses av alla användare med tillgång till den fliken.`}
                />
                <div className="form-group">
                    <label className="form-label fw-bold" htmlFor={`selection-comment-${targetGroup}`}>
                        Kommentar
                    </label>
                    <textarea
                        className="form-control mb-1"
                        id={`selection-comment-${targetGroup}`}
                        cols={5}
                        rows={3}
                        onChange={commentChangeHandler}
                        value={comment}
                    ></textarea>
                    {commentIsSaved ? (
                        <button className="btn btn-success">Kommentaren är sparad</button>
                    ) : (
                        <button className="btn btn-primary" onClick={saveButtonClickHandler}>
                            Spara kommentar
                        </button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SelectionCommentModal;
