import React, { CSSProperties } from "react";
import Site from "../../../types/Site";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getRemainingNumberOfOccasionsToPlan } from "../../../common/applicationUtils";

type ApplicationSiteSelectionTableRowProps = {
    site: Site;
    onTableRowClick: () => void;
};

const iconStyles: CSSProperties = {
    fontSize: 20
};

function ApplicationSiteSelectionTableRow({ site, onTableRowClick }: ApplicationSiteSelectionTableRowProps) {
    return (
        <tr onClick={onTableRowClick}>
            <td>{site.name}</td>
            <td>{site.roomName}</td>
            <td style={{ verticalAlign: "center" }}>
                {site.hasMatchingTechnology && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Spelplatsen uppfyller evenemangets teknikkrav</Tooltip>}
                    >
                        <i style={iconStyles} className="bi bi-mic-fill me-1 text-primary"></i>
                    </OverlayTrigger>
                )}
                {site.hasMatchingDimensions && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Spelplatsen uppfyller evenemangets dimensionskrav</Tooltip>}
                    >
                        <i style={iconStyles} className="bi bi-rulers me-1 text-secondary"></i>
                    </OverlayTrigger>
                )}

                {site.hasMatchingDates && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Spelplatsen och evenemanget har gemensamma möjliga datum</Tooltip>}
                    >
                        <i style={iconStyles} className="bi bi-calendar-check-fill me-1 text-success"></i>
                    </OverlayTrigger>
                )}
                {site.hasMatchingSiteType && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Spelplatsen uppfyller evenemangets krav på typ av spelplats</Tooltip>}
                    >
                        <i style={{ ...iconStyles, color: "#6610F2" }} className="bi bi-house-check-fill"></i>
                    </OverlayTrigger>
                )}
            </td>
            <td>
                <input
                    className="form-control text-end pe-0"
                    type="number"
                    disabled
                    value={site.numberOfAvailableSiteDates}
                />
            </td>
            <td>
                <input
                    className="form-control text-end pe-0"
                    type="number"
                    disabled
                    value={site.remainingNumberOfAvailableSiteDates}
                />
            </td>
        </tr>
    );
}

export default ApplicationSiteSelectionTableRow;
