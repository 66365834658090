import React from "react";
import { ApplicationPlanningStatus } from "../../../common/enums";

export type ApplicationPlanningFilterOptions = {
    searchText?: string;
    applicationPlanningStatus?: ApplicationPlanningStatus;
};

type ApplicationPlanningFilterProps = {
    selectedFilterOptions: ApplicationPlanningFilterOptions;
    onSelectedFilterOptionsChange: (updatedProperty: keyof ApplicationPlanningFilterOptions, updatedValue: any) => void;
};

const applicationPlanningStatusOptions = Object.entries(ApplicationPlanningStatus).map((entry) => (
    <option key={entry[0]} value={entry[1]}>
        {entry[1]}
    </option>
));

function ApplicationPlanningFilter({
    selectedFilterOptions,
    onSelectedFilterOptionsChange
}: ApplicationPlanningFilterProps) {
    return (
        <div className="row mb-3">
            <div className="col-md-3">
                <label className="form-label mb-0" htmlFor="search-input">
                    Fritextsök
                </label>
                <input
                    className="form-control"
                    type="text"
                    id="search-input"
                    placeholder="Sök på namn eller aktör"
                    value={
                        selectedFilterOptions && selectedFilterOptions.searchText
                            ? selectedFilterOptions.searchText
                            : ""
                    }
                    onChange={(event) => onSelectedFilterOptionsChange("searchText", event.target.value)}
                />
            </div>
            <div className="col-md-3">
                <label className="form-label mb-0" htmlFor="application-planning-status">
                    Programläggningsstatus
                </label>
                <select
                    className="form-select"
                    id="application-planning-status"
                    onChange={(event) => onSelectedFilterOptionsChange("applicationPlanningStatus", event.target.value)}
                >
                    <option value="">- Alla -</option>
                    {applicationPlanningStatusOptions}
                </select>
            </div>
        </div>
    );
}

export default ApplicationPlanningFilter;
