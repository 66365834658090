export class ApplicationPeriodCreateUpdate {
    enableTotalBudget() {
        const budgetInputs = Array.from(document.querySelectorAll<HTMLInputElement>("input[data-budget], input[data-budget-cm]"));

        budgetInputs.forEach((input) => input.addEventListener("change", this.updateTotalBudget));

        this.updateTotalBudget();
    }

    updateTotalBudget() {
        const budgetInputs = Array.from(document.querySelectorAll<HTMLInputElement>("input[data-budget]"));
        const budgetCMInputs = Array.from(document.querySelectorAll<HTMLInputElement>("input[data-budget-cm]"));

        const totalBudgetInput = document.getElementById("total-budget") as HTMLInputElement;
        const totalBudgetCMInput = document.getElementById("total-budget-cm") as HTMLInputElement;

        const currentTotalBudget = budgetInputs
            .map((input) => (input.value ? Number.parseFloat(input.value) : 0))
            .reduce((a, b) => a + b)
            .toString();

        const currentTotalBudgetCM = budgetCMInputs
            .map((input) => (input.value ? Number.parseFloat(input.value) : 0))
            .reduce((a, b) => a + b)
            .toString();

        totalBudgetInput.value = currentTotalBudget;
        totalBudgetCMInput.value = currentTotalBudgetCM;
    }
}
