import React, { useEffect, useState } from "react";
import Site from "../../types/Site";
import { pageLoadErrorMessage } from "../../common/errorMessages";
import LoadingSpinner from "../Common/LoadingSpinner";
import ErrorMessage from "../Common/Messages/ErrorMessage";
import SiteTableRow from "./SiteTableRow";
import { SecondarySiteType } from "../../common/enums";
import { fetchSitesWithPlannedOccasions } from "../../common/apiClient";

type SiteContractsProps = {
    applicationPeriodId: number;
};

export default function SiteContracts({ applicationPeriodId }: SiteContractsProps) {
    const [sites, setSites] = useState<Site[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const sitesFetchResult = await fetchSitesWithPlannedOccasions(applicationPeriodId);

            if (sitesFetchResult.errorMessage) {
                console.error(sitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            setSites(sitesFetchResult.value);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    const siteTableRows: JSX.Element[] = sites.map((s) => (
        <SiteTableRow key={s.id} site={s} onTableRowClick={() => tableRowClickHandler(applicationPeriodId, s)} />
    ));

    return (
        <div className="container mt-2">
            <h2>Avtalsunderlag - spelplatser</h2>
            <div className="border rounded" style={{ overflow: "hidden" }}>
                <table className="table table-hover-effect">
                    <thead>
                        <tr>
                            <th>Plats</th>
                            <th>Lokal</th>
                            <th>Intern/extern</th>
                        </tr>
                    </thead>
                    <tbody>{siteTableRows}</tbody>
                </table>
            </div>
        </div>
    );
}

function tableRowClickHandler(applicationPeriodId: number, site: Site) {
    const secondarySiteType = SecondarySiteType[site.secondarySiteType as keyof typeof SecondarySiteType];

    if (secondarySiteType === SecondarySiteType.Internal) {
        window.location.href = `/SiteContracts/${applicationPeriodId}/InternalSiteAgreement/${site.id}`;
    } else {
        window.location.href = `/SiteContracts/${applicationPeriodId}/ContractCreation/${site.id}`;
    }
}
