import * as bs from "bootstrap";

import AirDatepicker, { AirDatepickerViewsSingle } from "air-datepicker"; // https://air-datepicker.com/docs
import localeSv from "air-datepicker/locale/sv";
import { Tooltip } from "bootstrap"; // https://getbootstrap.com/docs/5.0/getting-started/javascript/
import { ValidationService } from "aspnet-client-validation"; // https://github.com/haacked/aspnet-client-validation
import Swal, { SweetAlertIcon } from "sweetalert2";

import Components from "./Components";
import ApplicationCreateUpdate from "../../Pages/Application/application_create_update";
import ApplicationsIndex from "../../Pages/Applications/applications_index";
import SiteCreateUpdate from "../../Pages/Sites/site_create_update";
import ApplicationPeriodsIndex from "../../Pages/ApplicationPeriods/application_periods_index";
import ApplicationDisplay from "../../Pages/Application/application_display";
import { ApplicationPeriodCreateUpdate } from "../../Pages/ApplicationPeriods/application_period_create_update";
import SitesIndex from "../../Pages/Sites/sites_index";
import { isSameDay } from "./common/dateUtils";

(window as any).components = new Components();
(window as any).AirDatepicker = AirDatepicker;
const validationService = new ValidationService();
(window as any).ValidationService = validationService;
(window as any).Swal = Swal;

(window as any).pages = {};
(window as any).pages.ApplicationCreateUpdate = new ApplicationCreateUpdate();
(window as any).pages.ApplicationDisplay = new ApplicationDisplay();
(window as any).pages.ApplicationsIndex = new ApplicationsIndex();
(window as any).pages.SiteCreateUpdate = new SiteCreateUpdate();
(window as any).pages.ApplicationPeriodsIndex = new ApplicationPeriodsIndex();
(window as any).pages.ApplicationPeriodCreateUpdate = new ApplicationPeriodCreateUpdate();
(window as any).pages.SitesIndex = new SitesIndex();

const enableDatepickers = () => {
    const blockedDates: string[] = (window as any).blockedDates || [];
    const datepickers = Array.from(document.querySelectorAll("input.datepicker-multiple")) as Array<HTMLInputElement>;

    for (let i = 0; i < datepickers.length; i++) {
        const datepickerInput = datepickers[i];
        datepickerInput.classList.add("d-none");

        const dates = (datepickers[i] as HTMLInputElement).value.split(",").map((d) => new Date(d));
        const airDatePicker = new AirDatepicker(datepickerInput, {
            locale: localeSv,
            inline: true,
            minDate: datepickerInput.dataset["from"] || false,
            maxDate: datepickerInput.dataset["to"] || false,
            multipleDates: true,
            onSelect({ datepicker }) {
                // This rerenders all cells when a date is clicked which disables blocked dates that were previously selected
                datepicker.update({});
            },
            onRenderCell: ({ date, cellType, datepicker }) => {
                datepicker.selectedDates;
                const dateString = date.toLocaleString().slice(0, 10);
                if (cellType === "day") {

                    // Blocked dates that are already selected should not be disabled to allow users to unselect these
                    if (
                        blockedDates.includes(dateString) &&
                        !datepicker.selectedDates.some((d) => isSameDay(date, d))
                    ) {
                        return {
                            disabled: true,
                            classes: "disabled-class",
                            attrs: {
                                title: "Inga tillfällen tillåtna detta datum"
                            }
                        };
                    }
                }
            }
        });

        dates.forEach((d) => {
            if (d) {
                airDatePicker.selectDate(d);
            }
        });
    }
};

const enableTooltips = () => {
    const tooltipTriggers = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    for (let i = 0; i < tooltipTriggers.length; i++) {
        new Tooltip(tooltipTriggers[i] as HTMLElement);
    }
};

const enableConfirmDelete = () => {
    const deleteButtons = document.querySelectorAll("button[data-confirm]");
    for (let i = 0; i < deleteButtons.length; i++) {
        const deleteButton = deleteButtons[i] as HTMLButtonElement;
        deleteButton.addEventListener("click", (e) => {
            e.preventDefault();
            const text = deleteButton.dataset["confirm"];
            const url = deleteButton.dataset["url"];
            Swal.fire({
                title: "Ta bort",
                text: text,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ja",
                cancelButtonText: "Avbryt"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const antiforgeryInput = document.querySelector(
                        'input[name="__RequestVerificationToken"]'
                    ) as HTMLInputElement;
                    const headers =
                        antiforgeryInput != null ? { RequestVerificationToken: antiforgeryInput.value } : {};
                    const response = await fetch(url, { method: "DELETE", headers });
                    if (response.ok) {
                        window.location.reload();
                    } else {
                        const data = await response.text();
                        alert(data);
                    }
                }
            });
        });
    }
};

const enableVisibleIf = () => {
    const extractValue = (el: HTMLElement): Array<string> => {
        if (el instanceof HTMLInputElement) {
            if (el.type && el.type === "checkbox") {
                return el.checked ? ["true"] : ["false"];
            }
        } else if (el instanceof HTMLSelectElement) {
            return el.value.split(",");
        }
        return null;
    };

    const extractValues = (els: Array<HTMLElement>): Array<string> => {
        if (els[0] instanceof HTMLInputElement) {
            if (els[0].type && els[0].type === "checkbox") {
                return els
                    .map((el) => ((el as HTMLInputElement).checked ? (el as HTMLInputElement).value : null))
                    .filter((x) => x != null);
            }
        }
        return null;
    };

    const toggleElement = (el: HTMLElement) => {
        const elVisibleIf = el.dataset["visibleIf"];
        const parts = elVisibleIf.split("==");
        if (parts.length === 2) {
            const inputSelector = parts[0];
            const requiredValue = parts[1];

            const inputs = Array.from(document.querySelectorAll(inputSelector)) as Array<HTMLElement>;

            if (inputs.length === 0) {
                return;
            } else if (inputs.length === 1) {
                let selectedValues = extractValue(inputs[0]);
                if (selectedValues.includes(requiredValue)) {
                    el.classList.remove("d-none");
                } else {
                    el.classList.add("d-none");
                }
            } else if (inputs.length > 1) {
                let selectedValues = extractValues(inputs);
                if (selectedValues.includes(requiredValue)) {
                    el.classList.remove("d-none");
                } else {
                    el.classList.add("d-none");
                }
            }
        }
    };

    const elements = document.querySelectorAll("[data-visible-if]");
    for (let i = 0; i < elements.length; i++) {
        const el = elements[i] as HTMLElement;
        const elVisibleIf = el.dataset["visibleIf"];

        const parts = elVisibleIf.split("==");
        if (parts.length === 2) {
            toggleElement(el);

            const inputSelector = parts[0];
            const inputs = document.querySelectorAll(inputSelector);
            for (let j = 0; j < inputs.length; j++) {
                const input = inputs[j] as HTMLElement;
                input.addEventListener("change", (e) => {
                    toggleElement(el);
                });
            }
        }
    }
};

interface AlertData {
    icon?: string;
    title?: string;
    text?: string;
}

const displayTempDataAlert = () => {
    const alertData = (window as any).alertData as AlertData;
    if (alertData != null && alertData.icon) {
        Swal.fire({
            icon: alertData.icon as SweetAlertIcon,
            title: alertData.title,
            html: alertData.text
        });
    }
};

window.addEventListener("DOMContentLoaded", () => {
    enableDatepickers();
    enableTooltips();
    enableConfirmDelete();
    enableVisibleIf();
    validationService.bootstrap({ watch: true, root: document.body });
    displayTempDataAlert();
});
