import React, { useEffect, useState } from "react";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import { pageLoadErrorMessage } from "../../../common/errorMessages";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorMessage from "../../Common/Messages/ErrorMessage";
import Site from "../../../types/Site";
import { applySitePlanningFilter } from "../../../common/siteUtils";
import SiteTableRow from "./SiteTableRow";
import InfoMessage from "../../Common/Messages/InfoMessage";
import {
    fetchApplicationPeriod,
    fetchApplicationsWithPlannedOccasions,
    fetchPlanningCommentsByApplicationPeriodId,
    fetchSitesForSitePlanning
} from "../../../common/apiClient";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import SitePlanningFilter, { SitePlanningFilterOptions } from "./SitePlanningFilter";
import PlanningOverviewAccordionItem from "../PlanningOverviewAccordionItem";
import PlannedApplicationSite from "../../../types/PlannedApplicationSite";
import PlanningComment from "../../../types/PlanningComment";
import { getAdjustedAgeTagsWithSelectedOccasions } from "../../../common/applicationUtils";
import { toGroupedAgeTags } from "../../../common/stringUtils";
import { sortPlannedApplicationSites } from "../../../common/planningUtils";

type SitePlanningProps = {
    applicationPeriodId: number;
};

const accordionId = "site-planning-accordion";

function SitePlanning({ applicationPeriodId }: SitePlanningProps) {
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [sites, setSites] = useState<Site[]>([]);
    const [plannedApplicationSites, setPlannedApplicationSites] = useState<PlannedApplicationSite[]>([]);
    const [selectedFilterOptions, setFilter] = useState<SitePlanningFilterOptions>();
    const [errorMessage, setErrorMessage] = useState("");
    const [planningComments, setPlanningComments] = useState<PlanningComment[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const [
                applicationPeriodFetchResult,
                sitesFetchResult,
                applicationsFetchResult,
                planningCommentsFetchResult
            ] = await Promise.all([
                fetchApplicationPeriod(applicationPeriodId),
                fetchSitesForSitePlanning(applicationPeriodId),
                fetchApplicationsWithPlannedOccasions(applicationPeriodId),
                fetchPlanningCommentsByApplicationPeriodId(applicationPeriodId)
            ]);

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (sitesFetchResult.errorMessage) {
                console.error(sitesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (applicationsFetchResult.errorMessage) {
                console.error(applicationsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            if (planningCommentsFetchResult.errorMessage) {
                console.error(planningCommentsFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            applicationsFetchResult.value.forEach((a) => {
                const adjustedAgeTags = getAdjustedAgeTagsWithSelectedOccasions(a, false);
                a.groupedAgeTags = toGroupedAgeTags(adjustedAgeTags);
            });

            sitesFetchResult.value.forEach((site) => {
                site.plannedApplicationSites.forEach((pas) => {
                    pas.application = applicationsFetchResult.value.find(
                        (application) => application.id === pas.applicationId
                    );
                });
            });

            setApplicationPeriod(applicationPeriodFetchResult.value);
            setSites(sitesFetchResult.value);
            setPlannedApplicationSites(
                sortPlannedApplicationSites(sitesFetchResult.value.flatMap((s) => s.plannedApplicationSites))
            );
            setPlanningComments(planningCommentsFetchResult.value);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    if (sites.length === 0) {
        return (
            <>
                <header>
                    <h2>
                        Programläggning - spelplatser: spelperiod {applicationPeriod.occasionsFrom.toLocaleDateString()}{" "}
                        till {applicationPeriod.occasionsTo.toLocaleDateString()}
                    </h2>
                </header>
                <InfoMessage message="Det finns inga spelplatser kopplade till anmälningsperioden." />
            </>
        );
    }

    const selectedFilterOptionsChangeHandler = (
        updatedProperty: keyof SitePlanningFilterOptions,
        updatedValue: any
    ) => {
        setFilter((prevValue) => ({ ...prevValue, [updatedProperty]: updatedValue }));
    };

    const filteredSites = applySitePlanningFilter(sites, selectedFilterOptions);

    const siteTableRows = filteredSites.map((s) => (
        <SiteTableRow site={s} key={s.id} applicationPeriodId={applicationPeriodId} />
    ));

    return (
        <div className="container mt-2">
            <header className="d-flex justify-content-between">
                <h2>
                    Programläggning - spelplatser: spelperiod {applicationPeriod.occasionsFrom.toLocaleDateString()}{" "}
                    till {applicationPeriod.occasionsTo.toLocaleDateString()}
                </h2>
            </header>

            <Accordion>
                <PlanningOverviewAccordionItem
                    plannedApplicationSites={plannedApplicationSites}
                    planningComments={planningComments}
                    applicationPeriod={applicationPeriod}
                    showExport={true}
                />
            </Accordion>

            <SitePlanningFilter
                selectedFilterOptions={selectedFilterOptions}
                onSelectedFilterOptionsChange={selectedFilterOptionsChangeHandler}
            />
            <div className="border rounded" style={{ overflow: "hidden" }}>
                <table className="table table-hover-effect">
                    <thead>
                        <tr>
                            <th>Plats</th>
                            <th>Lokalnamn</th>
                            <th style={{ width: "190px" }}>
                                <span className="me-1">Tillgängliga datum</span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Det totala antalet datum som kan programläggas på spelplatsen.
                                        </Tooltip>
                                    }
                                >
                                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                </OverlayTrigger>
                            </th>
                            <th style={{ width: "190px" }}>
                                <span className="me-1">Ej programlagda</span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            Det återstående antalet datum som kan programläggas på spelplatsen.
                                        </Tooltip>
                                    }
                                >
                                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                </OverlayTrigger>
                            </th>
                        </tr>
                    </thead>
                    <tbody>{siteTableRows}</tbody>
                </table>
            </div>
        </div>
    );
}

export default SitePlanning;
