import React from "react";
import Site from "../../types/Site";
import { SecondarySiteType } from "../../common/enums";

type SiteTableRowProps = {
    site: Site;
    onTableRowClick: () => void;
};  

function SiteTableRow({ site, onTableRowClick }: SiteTableRowProps) {
    return (
        <tr onClick={onTableRowClick}>
            <td>{site.name}</td>
            <td>{site.roomName}</td>
            <td>{SecondarySiteType[site.secondarySiteType as keyof typeof SecondarySiteType]}</td>
        </tr>
    );
}

export default SiteTableRow;
