import React, { CSSProperties } from "react";

type ProgressBarProps = {
    progress: number;
}

function ProgressBar({ progress }: ProgressBarProps) {
    const containerStyles: CSSProperties = {
        height: "16px",
        backgroundColor: "#e0e0de",
        borderRadius: "2rem",
        width: "360px"
    }

    const fillerStyle: CSSProperties = {
        height: "100%",
        width: `${Math.min(progress * 100, 100)}%`,
        backgroundColor: progress > 1 ? "#DC3545" :"#086EFD" ,
        textAlign: "right",
        borderRadius: 'inherit',
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyle}></div>
        </div>
    );
}

export default ProgressBar;