export enum TargetGroup {
    PreSchool = "PreSchool",
    SchoolLower = "SchoolLower",
    SchoolMiddle = "SchoolMiddle",
    SchoolUpper = "SchoolUpper",
    HighSchool = "HighSchool",
    SpecialNeedsSchool = "SpecialNeedsSchool",
    DayCareSchool = "DayCareSchool",
    SFI = "SFI",
    Other = "Other",
    Total = "Total"
}

export enum Tag {
    Nycirkus = "Nycirkus",
    Dans = "Dans",
    RörligBild = "Rörlig bild",
    Konst = "Konst",
    Litteratur = "Litteratur",
    Musik = "Musik",
    Performance = "Performance",
    Slöjd = "Slöjd",
    Teater = "Teater"
}

export enum AgeTag {
    Age3 = "3 år",
    Age4 = "4 år",
    Age5 = "5 år",
    Year0 = "F-klass",
    Year1 = "Åk1",
    Year2 = "Åk2",
    Year3 = "Åk3",
    Year4 = "Åk4",
    Year5 = "Åk5",
    Year6 = "Åk6",
    Year7 = "Åk7",
    Year8 = "Åk8",
    Year9 = "Åk9",
    HighSchoolYear1 = "Gymnasie Åk1",
    HighSchoolYear2 = "Gymnasie Åk2",
    HighSchoolYear3 = "Gymnasie Åk3",
    HighSchoolYear4 = "Gymnasie Åk4",
    SpecialNeedsSchoolOld = "Särskola",
    SpecialNeedsSchool = "Anpassad skola",
    LeisureCenter = "Fritidshem",
    SFI = "SFI"
}

export enum SiteType {
    Outdoors = "Utomhus",
    Library = "Kulturhus/Bibliotek",
    School = "Klassrum/Förskola",
    OwnStage = "Egen scen"
}

export enum SecondarySiteType {
    Internal = "Intern",
    External = "Extern",
    Outdoors = "Utomhus",
    Visiting = "Uppsökande"
}

export enum ApplicationPlanningStatus {
    HasOccasionsToPlan = "Tillfällen kvar att programlägga",
    HasNoOccasionsToPlan = "Inga tillfällen kvar att programlägga"
}

export enum SitePlanningStatus {
    HasAvailableDates = "Datum kvar att programlägga",
    HasNoAvailableDates = "Inga datum kvar att programlägga"
}

export enum CityArea {
    NorthEast = "Nordost",
    SouthWest = "Sydväst",
    Hisingen = "Hisingen",
    Center = "Centrum"
}

export enum HexColor {
    Blue = "#B7DAFF",
    Grey = "#D6D8DB",
    Green = "#C3E6CB",
    Red = "#F4C6CC",
    Yellow = "#FEEEBA",
    Purple = "#C4B3E6",
}
