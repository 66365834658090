import React, { useEffect, useState } from "react";
import { postBlockedDate, deleteBlockedDate, fetchBlockedDates } from "../../common/apiClient";
import { pageLoadErrorMessage } from "../../common/errorMessages";
import LoadingSpinner from "../Common/LoadingSpinner";
import ErrorMessage from "../Common/Messages/ErrorMessage";
import { DayPicker } from "react-day-picker";
import { getCaptionText, getWeekDayName, isSameDay } from "../../common/dateUtils";
import Result from "../../types/Result";

export default function BlockedDates() {
    const [blockedDates, setBlockedDates] = useState<Date[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const blockedDatesFetchResult = await fetchBlockedDates();

            if (blockedDatesFetchResult.errorMessage) {
                console.error(blockedDatesFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }
            setBlockedDates(blockedDatesFetchResult.value);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    const dateClickHandler = async (date: Date) => {
        const dateIsBlocked = blockedDates.some((b) => isSameDay(b, date));

        let result: Result<unknown>;

        if (dateIsBlocked) {
            setBlockedDates((prevValue) => prevValue.filter((b) => !isSameDay(b, date)));
            result = await deleteBlockedDate(date);
        } else {
            setBlockedDates((prevValue) => [...prevValue, date]);
            result = await postBlockedDate(date);
        }

        if (result.errorMessage) {
            console.error(result.errorMessage);
            setErrorMessage(pageLoadErrorMessage);
        }
    };

    return (
        <>
            <h1 className="mb-4 text-center">Blockerade datum</h1>
            <div className="d-flex justify-content-center">
                <div className="border rounded">
                    <DayPicker
                        mode="multiple"
                        weekStartsOn={1}
                        formatters={{
                            formatWeekdayName: getWeekDayName,
                            formatCaption: getCaptionText
                        }}
                        onDayClick={dateClickHandler}
                        selected={blockedDates}
                    />
                </div>
            </div>
        </>
    );
}
