import React from "react";
import { SiteType } from "../../common/enums";
import Site from "../../types/Site";


type SiteTableRowProps = {
    site: Site,
    applicationPeriodId: number;
    onCalenderButtonClick: (site: Site) => void;
}

function SiteTableRow({ site, applicationPeriodId, onCalenderButtonClick }: SiteTableRowProps) {
    const numberOfAvailableDates = site.availableSiteDates
        .filter(a => a.applicationPeriodId === applicationPeriodId)
        .length;

    return <tr>
        <td>
            <button className="btn btn-primary" onClick={() => onCalenderButtonClick(site)}>
                <i className="bi bi-calendar-week"></i>
            </button>
        </td>
        <td>{site.name}</td>
        <td>{site.roomName}</td>
        <td>{SiteType[site.siteType as keyof typeof SiteType]}</td>
        <td><input className="form-control text-end pe-0" type="number" disabled value={numberOfAvailableDates} /></td>
    </tr>

}

export default SiteTableRow;