import React from "react";
import Site from "../../../types/Site";
import { SiteType } from "../../../common/enums";
import { Accordion } from "react-bootstrap";

type SiteDetailsAccordionProps = {
    site: Site;
};

export default function SiteDetailsAccordionItem({ site }: SiteDetailsAccordionProps) {
    return (
        <Accordion.Item eventKey="SiteDetails">
            <Accordion.Header>
                <span className="fw-bold">Information om spelplats</span>
            </Accordion.Header>
            <Accordion.Body>
                <div className="mt-2 mb-2">
                    <label className="form-label ">Plats</label>
                    <input className="form-control" value={site.name} disabled />
                </div>

                <div className="mb-2 row">
                    <div className="col-md-4">
                        <label className="form-label">Typ av lokal</label>
                        <input
                            className="form-control"
                            type="text"
                            value={SiteType[site.siteType as keyof typeof SiteType]}
                            disabled
                        />
                    </div>
                    <div className="col-md-8">
                        <label className="form-label">Namn på lokal</label>
                        <input className="form-control" value={site.roomName} disabled />
                    </div>
                </div>

                <div className="mb-2">
                    <label className="form-label ">Adress</label>
                    <input className="form-control" value={site.address} disabled />
                </div>

                <div className="row mb-2">
                    <div className="col-md-4">
                        <label className="form-label ">Postnummer</label>
                        <input className="form-control" value={site.postalCode} disabled />
                    </div>
                    <div className="col-md-8">
                        <label className="form-label">Ort</label>
                        <input className="form-control" value={site.postalAddress} disabled />
                    </div>
                </div>

                <div className="mb-2">
                    <label className="form-label ">Stadsområde</label>
                    <input className="form-control" value={site.cityArea} disabled />
                </div>

                <fieldset className="mb-4">
                    <legend style={{ fontSize: "1rem" }}>
                        <span>Vilken befintlig teknik finns i lokalen?</span>
                    </legend>
                    <div className="row">
                        <div className="col-md-2">
                            <input className="form-check-input me-2" type="checkbox" checked={site.hasPA} disabled />
                            <label className="form-check-label">PA</label>
                        </div>
                        <div className="col-md-2">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                checked={site.hasLighting}
                                disabled
                            />
                            <label className="form-check-label">Ljus</label>
                        </div>
                        <div className="col-md-2">
                            <input className="form-check-input me-2" type="checkbox" checked={site.hasSound} disabled />
                            <label className="form-check-label">Ljud</label>
                        </div>
                        <div className="col-md-2">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                checked={site.hasBlackbox}
                                disabled
                            />
                            <label className="form-check-label">Blackbox</label>
                        </div>
                        <div className="col-md-2">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                checked={site.hasBlackout}
                                disabled
                            />
                            <label className="form-check-label">Mörkläggning</label>
                        </div>
                    </div>
                </fieldset>

                <fieldset className="mb-2">
                    <legend style={{ fontSize: "1rem" }}>
                        <span>Spelyta</span>
                    </legend>
                    <div className="row">
                        <div className="col-md-4">
                            <label className="form-label">Djup</label>
                            <input className="form-control" value={site.stageDepth} disabled />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Bredd</label>
                            <input className="form-control" value={site.stageWidth} disabled />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">Höjd</label>
                            <input className="form-control" value={site.stageHeight} disabled />
                            <span className="form-text"></span>
                        </div>
                    </div>
                </fieldset>

                <div className="row mb-2">
                    <div className="col-md-4">
                        <label className="form-label">Antal publikplatser</label>
                        <input className="form-control" value={site.numberOfSeats} disabled />
                    </div>
                    <div className="col-md-8">
                        <label className="form-label">Typ av publikplatser</label>
                        <input className="form-control" value={site.typeOfSeats} disabled />
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label">Övriga befintliga faciliteter</label>
                    <textarea className="form-control" rows={3} value={site.otherFacilities ?? ""} disabled></textarea>
                </div>

                <div className="mb-3">
                    <label className="form-label">Övrigt</label>
                    <textarea className="form-control" rows={3} value={site.otherInformation ?? ""} disabled></textarea>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
}
