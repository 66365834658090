import React from "react";

type SuccessMessageProps = {
    message: string;
};

function SuccessMessage({ message }: SuccessMessageProps) {
    return <div className="alert alert-success">{message}</div>;
}

export default SuccessMessage;
