import React, { useEffect, useState } from "react";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import { fetchApplicationPeriod, sendSelectionSummary, sendTestSelectionSummary } from "../../common/apiClient";
import { pageLoadErrorMessage } from "../../common/errorMessages";
import InfoMessage from "../Common/Messages/InfoMessage";
import LoadingSpinner from "../Common/LoadingSpinner";
import ErrorMessage from "../Common/Messages/ErrorMessage";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";

type SelectionSummaryMailProps = {
    applicationPeriodId: number;
    userEmail: string;
};

function SelectionSummaryMail({ applicationPeriodId, userEmail }: SelectionSummaryMailProps) {
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [numberOfActors, setNumberOfActors] = useState<number>();
    const [numberOfApplications, setNumberOfApplications] = useState<number>();
    const [numberOfSelectedApplications, setNumberOfSelectedApplications] = useState<number>();
    const [numberOfSelectedActors, setNumberOfSelectedActors] = useState<number>();
    const [numberOfSelectedOccasions, setNumberOfSelectedOccasions] = useState<number>();
    const [numberOfActorsWithOperationalSupport, setNumberOfActorsWithOperationalSupport] = useState<number>();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const applicationPeriodFetchResult = await fetchApplicationPeriod(applicationPeriodId);

            if (applicationPeriodFetchResult.errorMessage) {
                console.error(applicationPeriodFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }

            setApplicationPeriod(applicationPeriodFetchResult.value);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    const sendSummaryClickHandler = async () => {
        const postResult = await sendSelectionSummary(
            applicationPeriodId,
            numberOfApplications,
            numberOfActors,
            numberOfSelectedApplications,
            numberOfSelectedActors,
            numberOfSelectedOccasions,
            numberOfActorsWithOperationalSupport
        );

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            Swal.fire({
                title: "Något gick fel när utskicket gjordes",
                text: "Vänligen försök igen. Kontakta support om problemet kvarstår.",
                icon: "error"
            });
            return;
        }

        Swal.fire({
            title: "Beskedet har skickats",
            icon: "success"
        });
    };

    const sendTestClickHandler = async () => {
        const postResult = await sendTestSelectionSummary(
            applicationPeriodId,
            numberOfApplications,
            numberOfActors,
            numberOfSelectedApplications,
            numberOfSelectedActors,
            numberOfSelectedOccasions,
            numberOfActorsWithOperationalSupport
        );

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            Swal.fire({
                title: "Något gick fel när testutskicket gjordes",
                text: "Vänligen försök igen. Kontakta support om problemet kvarstår.",
                icon: "error"
            });
            return;
        }

        Swal.fire({
            title: "Testet har skickats till " + userEmail,
            icon: "success"
        });
    };

    const disableSend =
        !numberOfActors ||
        !numberOfApplications ||
        !numberOfSelectedActors ||
        !numberOfSelectedApplications ||
        !numberOfSelectedOccasions ||
        !numberOfActorsWithOperationalSupport;

    return (
        <div>
            <h2 className="mb-4">{applicationPeriod.name} - Besked efter urval</h2>

            <InfoMessage
                message={`Här gör du ett utskick till samtliga aktörer som skapade en intresseanmälan under anmälningsperiod ${applicationPeriod.name}. 
                          Utskicket kommer att innehålla information om vilka aktörer och evenemang som valdes under urvalsprocessen.`}
            />

            <div className="row mb-4">
                <div className="col-md-6">
                    <label className="form-label">
                        <span className="me-1">Antal aktörer</span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Här anger du det totala antalet sökande aktörer under anmälningsperiod{" "}
                                    {applicationPeriod.name}.
                                </Tooltip>
                            }
                        >
                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                        </OverlayTrigger>
                    </label>

                    <input
                        className="form-control"
                        type="number"
                        value={numberOfActors}
                        onChange={(e) => setNumberOfActors(Number.parseInt(e.target.value))}
                    />
                </div>

                <div className="col-md-6">
                    <label className="form-label">
                        <span className="me-1">Antal intresseanmälningar</span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Här anger du det totala antalet intresseanmälningar som skapades under
                                    anmälningsperiod {applicationPeriod.name}.
                                </Tooltip>
                            }
                        >
                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                        </OverlayTrigger>
                    </label>
                    <input
                        className="form-control"
                        type="number"
                        value={numberOfApplications}
                        onChange={(e) => setNumberOfApplications(Number.parseInt(e.target.value))}
                    />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-6">
                    <label className="form-label">
                        <span className="me-1">Antal valda aktörer</span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Här anger du det totala antalet valda aktörer från anmälningsperiod{" "}
                                    {applicationPeriod.name}.
                                </Tooltip>
                            }
                        >
                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                        </OverlayTrigger>
                    </label>

                    <input
                        className="form-control"
                        type="number"
                        value={numberOfSelectedActors}
                        onChange={(e) => setNumberOfSelectedActors(Number.parseInt(e.target.value))}
                    />
                </div>

                <div className="col-md-6">
                    <label className="form-label">
                        <span className="me-1">Antal valda intresseanmälningar</span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Här anger du det totala antalet valda intresseanmälningar från anmälningsperiod{" "}
                                    {applicationPeriod.name}.
                                </Tooltip>
                            }
                        >
                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                        </OverlayTrigger>
                    </label>
                    <input
                        className="form-control"
                        type="number"
                        value={numberOfSelectedApplications}
                        onChange={(e) => setNumberOfSelectedApplications(Number.parseInt(e.target.value))}
                    />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-6">
                    <label className="form-label">
                        <span className="me-1">Antal valda tillfällen</span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Här anger du det totala antalet tillfällen som valdes under urvalsprocessen efter
                                    anmälningsperiod {applicationPeriod.name}.
                                </Tooltip>
                            }
                        >
                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                        </OverlayTrigger>
                    </label>

                    <input
                        className="form-control"
                        type="number"
                        value={numberOfSelectedOccasions}
                        onChange={(e) => setNumberOfSelectedOccasions(Number.parseInt(e.target.value))}
                    />
                </div>

                <div className="col-md-6">
                    <label className="form-label">
                        <span className="me-1">Antal aktörer med verksamhetsstöd</span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Här anger du hur många av de sökande aktörerna under anmälningsperiod{" "}
                                    {applicationPeriod.name} som hade verksamhetsstöd.
                                </Tooltip>
                            }
                        >
                            <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                        </OverlayTrigger>
                    </label>
                    <input
                        className="form-control"
                        type="number"
                        value={numberOfActorsWithOperationalSupport}
                        onChange={(e) => setNumberOfActorsWithOperationalSupport(Number.parseInt(e.target.value))}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-between">
                <button className="btn btn-warning" disabled={disableSend} onClick={sendTestClickHandler}>
                    <i className="bi bi-send me-1"></i>
                    Skicka test till {userEmail}
                </button>
                <button className="btn btn-primary" disabled={disableSend} onClick={sendSummaryClickHandler}>
                    <i className="bi bi-send me-1"></i>
                    Skicka besked
                </button>
            </div>
        </div>
    );
}

export default SelectionSummaryMail;
