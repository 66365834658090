import React from "react";
import Application from "../../../types/Application";
import ApplicationPeriod from "../../../types/ApplicationPeriod";
import { getCaptionText, getMonthsBetween, getWeekDayName, isSameDay } from "../../../common/dateUtils";
import { DayPicker } from "react-day-picker";
import CalenderModal from "../../SiteSelection/CalenderModal";
import NoScrollCalenderCaptions from "../../Common/Calender/NoScrollCalenderCaptions";
import { Accordion } from "react-bootstrap";

type ApplicationDatesAccordionItemProps = {
    application: Application;
    applicationPeriod: ApplicationPeriod;
};

function ApplicationDatesAccordionItem({ application, applicationPeriod }: ApplicationDatesAccordionItemProps) {
    const displayedMonths: Date[] = getMonthsBetween(applicationPeriod.occasionsFrom, applicationPeriod.occasionsTo);
    const possibleDates = application.document.possibleOccasionDates;

    const calenderElements = displayedMonths.map((month) => (
        <div className="col-xxl-3 col-md-4" key={month.getTime()}>
            <DayPicker
                defaultMonth={month}
                weekStartsOn={1}
                components={{ Caption: NoScrollCalenderCaptions }}
                formatters={{ formatWeekdayName: getWeekDayName }}
                selected={(calenderDate) => possibleDates.some((possibleDate) => isSameDay(calenderDate, possibleDate))}
            />
        </div>
    ));

    return (
        <Accordion.Item eventKey="0">
            <Accordion.Header>
                <span className="fw-bold">Möjliga datum för evenemanget</span>
            </Accordion.Header>
            <Accordion.Body>
                <div className="row">{calenderElements}</div>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default ApplicationDatesAccordionItem;
