interface ActorInfo {
    actorDescription: string,
    organisationNumber: string,
    signatory: string,
    actorAddress: string,
    actorPostalCode: string,
    actorPostalAddress: string,
    contactName: string,
    contactPhoneNumber: string,
    contactEmailAddress: string
}

export default class ApplicationCreateUpdate {
    enable() {
        const id = parseInt((document.getElementById('ViewModel_Id') as HTMLInputElement).value || '0');
        const isNewApplication = id === 0;
        
        if (isNewApplication) {
            this.watchCultureProviderChanges();
        }
        
        this.watchTicketPriceChanges();
        this.updateTotalTicketPrice();
        
        this.watchOccasionPriceChanges();
        this.updateTotalOccasionPrice();
    }
    
    watchTicketPriceChanges() {
        const ticketPriceInput = document.getElementById('ViewModel_TicketPrice') as HTMLInputElement;
        const numberOfSeatsInput = document.getElementById('ViewModel_NumberOfSeats') as HTMLInputElement;
        
        ticketPriceInput.addEventListener('change', e => this.updateTotalTicketPrice());
        numberOfSeatsInput.addEventListener('change', e => this.updateTotalTicketPrice());
    }
    
    updateTotalTicketPrice() {
        const ticketPrice = parseInt((document.getElementById('ViewModel_TicketPrice') as HTMLInputElement).value || '0');
        const numberOfSeats = parseInt((document.getElementById('ViewModel_NumberOfSeats') as HTMLInputElement).value || '0');
        const totalPriceInput = document.getElementById('ViewModel_TotalTicketPrice') as HTMLInputElement;
        totalPriceInput.value = `${ticketPrice * numberOfSeats}`;
    }

    watchOccasionPriceChanges() {
        const occasionPriceInput = document.getElementById('ViewModel_OccasionPrice') as HTMLInputElement;
        const occasionPrice2Input = document.getElementById('ViewModel_OccasionPrice2') as HTMLInputElement;

        occasionPriceInput.addEventListener('change', e => this.updateTotalOccasionPrice());
        occasionPrice2Input.addEventListener('change', e => this.updateTotalOccasionPrice());
    }

    updateTotalOccasionPrice() {
        const occasionPrice = parseInt((document.getElementById('ViewModel_OccasionPrice') as HTMLInputElement).value || '0');
        const occasionPrice2 = parseInt((document.getElementById('ViewModel_OccasionPrice2') as HTMLInputElement).value || '0');
        const totalPriceInput = document.getElementById('ViewModel_TotalOccasionPrice') as HTMLInputElement;
        totalPriceInput.value = `${occasionPrice + occasionPrice2}`;
    }


    watchCultureProviderChanges() {
        const cultureProviderSelect = document.getElementById('ViewModel_CultureProviderId') as HTMLSelectElement;
        cultureProviderSelect.addEventListener('change', async (event) => {
            const currentCultureProviderId = parseInt(cultureProviderSelect.value || '0');
            if (currentCultureProviderId === 0) {
                this.clearCultureProviderInfo();
            } else {
                const response = await fetch(`/api/actor/${currentCultureProviderId}`);
                const actorInfo = await response.json() as ActorInfo;
                this.applyActorInfo(actorInfo);
            }
        });
    }
    
    clearCultureProviderInfo() {
        (document.getElementById('ViewModel_ActorDescription') as HTMLInputElement).value = '';
        (document.getElementById('ViewModel_OrganisationNumber') as HTMLInputElement).value = '';
        (document.getElementById('ViewModel_Signatory') as HTMLInputElement).value = '';
        (document.getElementById('ViewModel_ActorAddress') as HTMLInputElement).value = '';
        (document.getElementById('ViewModel_ActorPostalCode') as HTMLInputElement).value = '';
        (document.getElementById('ViewModel_ActorPostalAddress') as HTMLInputElement).value = '';
        (document.getElementById('ViewModel_ContactName') as HTMLInputElement).value = '';
        (document.getElementById('ViewModel_ContactPhoneNumber') as HTMLInputElement).value = '';
        (document.getElementById('ViewModel_ContactEmailAddress') as HTMLInputElement).value = '';
    }
    
    applyActorInfo(actorInfo: ActorInfo) {
        (document.getElementById('ViewModel_ActorDescription') as HTMLInputElement).value = actorInfo.actorDescription;
        (document.getElementById('ViewModel_OrganisationNumber') as HTMLInputElement).value = actorInfo.organisationNumber;
        (document.getElementById('ViewModel_Signatory') as HTMLInputElement).value = actorInfo.signatory;
        (document.getElementById('ViewModel_ActorAddress') as HTMLInputElement).value = actorInfo.actorAddress;
        (document.getElementById('ViewModel_ActorPostalCode') as HTMLInputElement).value = actorInfo.actorPostalCode;
        (document.getElementById('ViewModel_ActorPostalAddress') as HTMLInputElement).value = actorInfo.actorPostalAddress;
        (document.getElementById('ViewModel_ContactName') as HTMLInputElement).value = actorInfo.contactName;
        (document.getElementById('ViewModel_ContactPhoneNumber') as HTMLInputElement).value = actorInfo.contactPhoneNumber;
        (document.getElementById('ViewModel_ContactEmailAddress') as HTMLInputElement).value = actorInfo.contactEmailAddress;
    }
    
}