import React from "react";
import Site from "../../../types/Site";

type SiteTableRowProps = {
    site: Site;
    applicationPeriodId: number;
};

function SiteTableRow({ site, applicationPeriodId }: SiteTableRowProps) {
    const rowClickHandler = () => {
        window.location.href = `/sites/${site.id}/applicationSelection/${applicationPeriodId}`;
    };
    
    return (
        <tr style={{ cursor: "pointer" }} onClick={rowClickHandler}>
            <td>{site.name}</td>
            <td>{site.roomName}</td>
            <td>
                <input
                    className="form-control text-end pe-0"
                    type="number"
                    disabled
                    value={site.numberOfAvailableSiteDates}
                />
            </td>
            <td>
                <input className="form-control text-end pe-0" type="number" disabled value={site.remainingNumberOfAvailableSiteDates} />
            </td>
        </tr>
    );
}

export default SiteTableRow;
