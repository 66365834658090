import React from "react";
import CultureProvider from "../../types/CultureProvider";

type ActorTableRowProps = {
    cultureProvider: CultureProvider;
    onTableRowClick: () => void;
};

function ActorTableRow({ cultureProvider, onTableRowClick }: ActorTableRowProps) {
    return <tr onClick={onTableRowClick}>
        <td>
            {cultureProvider.name}
        </td>
    </tr>;
}

export default ActorTableRow;
