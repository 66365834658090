import React from "react";
import PlannedApplicationSite from "../../types/PlannedApplicationSite";
import { tableCellStyles } from "../../common/inlineStyles";
import { formatTime, getTimeSpanString } from "../../common/dateUtils";
import { hasTwoOccasions } from "../../common/planningUtils";

type SiteScheduleTableRowProps = {
    plannedApplicationSite: PlannedApplicationSite;
    availableSiteTime: AvailableSiteTime;
};

export default function SiteScheduleTableRow({ plannedApplicationSite, availableSiteTime }: SiteScheduleTableRowProps) {
    return (
        <tr>
            <td style={tableCellStyles}>{plannedApplicationSite.occasion1Starts.toLocaleDateString()}</td>
            <td style={tableCellStyles}>
                {formatTime(availableSiteTime.availableFrom)} - {formatTime(availableSiteTime.availableTo)}
            </td>
            <td style={tableCellStyles}>{plannedApplicationSite.application.document.eventName}</td>
            <td style={tableCellStyles}>
                {getTimeSpanString(plannedApplicationSite.occasion1Starts, plannedApplicationSite.occasion1Ends)}
            </td>
            <td style={tableCellStyles}>
                {hasTwoOccasions(plannedApplicationSite)
                    ? getTimeSpanString(plannedApplicationSite.occasion2Starts, plannedApplicationSite.occasion2Ends)
                    : ""}
            </td>
            <td style={tableCellStyles}>{plannedApplicationSite.application.actorName}</td>
            <td style={tableCellStyles}>
                {plannedApplicationSite.application.document.contactName} <br />
                {plannedApplicationSite.application.document.contactPhoneNumber} <br />
                {plannedApplicationSite.application.document.contactEmailAddress}
            </td>
        </tr>
    );
}
