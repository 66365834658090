import React from "react";
import Application from "../../types/Application";
import { Modal } from "react-bootstrap";
import { adjustPriceModalId } from "./AdjustPriceModal";
import { AgeTag, TargetGroup } from "../../common/enums";
import {
    getAdjustedAgeTagsByTargetGroup,
    getApplicationTargetGroupExclusion
} from "../../common/applicationUtils";
import { toGroupedAgeTags } from "../../common/stringUtils";

type ApplicationDetailsPopupProps = {
    application: Application;
    show: boolean;
    onHide: () => void;
    onShowAdjustPriceModal: () => void;
    onShowSelectionCommentModal: () => void;
    onShowApplicationSelectionColorModal: () => void;
    onShowAdjustSelectionAgeTagsModal: () => void;
    targetGroup: TargetGroup;
    isCulturalManagement: boolean;
    onHideApplication: (application: Application) => void;
    onShowApplication: (application: Application) => void;
};

function ApplicationDetailsModal({
    application,
    show,
    onHide,
    onShowAdjustPriceModal,
    onShowSelectionCommentModal,
    onShowApplicationSelectionColorModal,
    onShowAdjustSelectionAgeTagsModal,
    targetGroup,
    isCulturalManagement,
    onHideApplication,
    onShowApplication
}: ApplicationDetailsPopupProps) {
    const possibleSites = application.document.possibleSiteTypes;
    const schoolSiteRequirements = application.document.school_SiteRequirements;
    const librarySiteRequirements = application.document.library_SiteRequirements;
    const outdoorsSiteRequirements = application.document.outdoors_SiteRequirements;

    const applicationTargetGroupExclusion = getApplicationTargetGroupExclusion(
        application,
        targetGroup,
        isCulturalManagement
    );

    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton>
                <h4 className="modal-title">{application.document.eventName}</h4>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                <div className="mb-4">
                    <h4 className="mb-2">Evenemangsinformation</h4>
                    <div className="mb-2 container-fluid row m-0 p-0">
                        <div className="col-6 ps-0">
                            <label className="form-label mb-0">Aktör</label>
                            <input className="form-control" disabled value={application.actorName} type="text" />
                        </div>
                        <div className="col-6 pe-0">
                            <label className="form-label mb-0">Evenemang</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.document.eventName}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="mb-2">
                        <label className="form-label mb-0">Kort beskrivning</label>
                        <textarea
                            className="form-control"
                            disabled
                            value={application.document.eventShortDescription ?? ""}
                        ></textarea>
                    </div>
                    <div className="mb-2 container-fluid row m-0 p-0">
                        <div className="col-6 ps-0">
                            <label className="form-label mb-0">Målgrupp</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.groupedAgeTags.join(", ")}
                                type="text"
                            />
                        </div>
                        <div className="col-6 pe-0">
                            <label className="form-label mb-0">Konstformer</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.document.tags.join(", ")}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="mb-2 container-fluid row m-0 p-0">
                        <div className="col-6 ps-0">
                            <label className="form-label mb-0">Evenemangets längd</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.document.eventDuration.split(":").slice(0, 2).join(":")}
                                type="text"
                            />
                        </div>
                        <div className="col-6 pe-0">
                            <label className="form-label mb-0">Maxpublik (inkluderat vuxna)</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.document.maxNumberOfSeats}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="container-fluid row m-0 p-0">
                        {application.document.eventDetailsUrl && (
                            <div className="col-6 ps-0">
                                <div>
                                    <label className="form-label mb-0">Länk till webbsida för evenemanget</label>
                                </div>
                                <a href={getUrlWithProtocol(application.document.eventDetailsUrl)} target="_blank">
                                    {application.document.eventDetailsUrl}
                                </a>
                            </div>
                        )}

                        {application.document.eventVideoUrl && (
                            <div className="col-6 pe-0">
                                <div>
                                    <label className="form-label mb-0">Länk till inspelat material/trailer</label>
                                </div>
                                <a href={getUrlWithProtocol(application.document.eventVideoUrl)} target="_blank">
                                    {application.document.eventVideoUrl}
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                <div className="mb-4">
                    <h4 className="mb-2">Spelplats</h4>
                    <div className="d-flex mb-3">
                        <span className="me-5">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                disabled
                                checked={possibleSites.includes("OwnStage")}
                            />{" "}
                            Egen scen
                        </span>
                        <span className="me-5">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                disabled
                                checked={possibleSites.includes("School")}
                            />
                            Klassrum/Förskola
                        </span>
                        <span className="me-5">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                disabled
                                checked={possibleSites.includes("Library")}
                            />
                            Kulturhus/Bibliotek
                        </span>
                        <span className="me-5">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                disabled
                                checked={possibleSites.includes("Outdoors")}
                            />
                            Utomhus
                        </span>
                    </div>
                    {possibleSites.includes("School") && (
                        <div className="mb-3">
                            <h5 className="mb-0">Klassrum/Förskola</h5>
                            <div className="alert alert-secondary">
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Scenstorlek</label>
                                    </div>
                                    <span className="fw-bold">Bredd:</span>{" "}
                                    <span className="me-3">{application.document.school_StageWidth} meter</span>
                                    <span className="fw-bold">Djup:</span>{" "}
                                    <span className="me-3">{application.document.school_StageDepth} meter</span>
                                    <span className="fw-bold">Höjd:</span>{" "}
                                    <span className="me-3">{application.document.school_StageHeight} meter</span>
                                </div>
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Behov av teknik</label>
                                    </div>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("PA")}
                                        />{" "}
                                        PA
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("Lighting")}
                                        />{" "}
                                        Ljus
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("Sound")}
                                        />{" "}
                                        Ljud
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("Blackbox")}
                                        />
                                        Blackbox
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("Blackout")}
                                        />
                                        Mörkläggning
                                    </span>
                                </div>
                                <div className="mb-2">
                                    <label className="form-label mb-0">Övriga behov kring teknik och lokal</label>
                                    <textarea
                                        className="form-control"
                                        disabled
                                        value={application.document.school_OtherSiteRequirements ?? ""}
                                    ></textarea>
                                </div>
                                <div className="mb-2">
                                    <span className="fw-bold">Byggtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.school_BuildTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                    <span className="fw-bold">Rivtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.school_StrikeTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                </div>
                                <div>
                                    <input
                                        className="form-check-input me-1"
                                        type="checkbox"
                                        disabled
                                        checked={application.document.school_IsAssistanceRequired}
                                    />{" "}
                                    Bärhjälp krävs
                                </div>
                            </div>
                        </div>
                    )}
                    {possibleSites.includes("Library") && (
                        <div className="mb-3">
                            <h5 className="mb-0">Kulturhus/Bibliotek</h5>
                            <div className="alert alert-secondary">
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Scenstorlek</label>
                                    </div>
                                    <span className="fw-bold">Bredd:</span>{" "}
                                    <span className="me-3">{application.document.library_StageWidth} meter</span>
                                    <span className="fw-bold">Djup:</span>{" "}
                                    <span className="me-3">{application.document.library_StageDepth} meter</span>
                                    <span className="fw-bold">Höjd:</span>{" "}
                                    <span className="me-3">{application.document.library_StageHeight} meter</span>
                                </div>
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Behov av teknik</label>
                                    </div>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("PA")}
                                        />{" "}
                                        PA
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("Lighting")}
                                        />{" "}
                                        Ljus
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("Sound")}
                                        />{" "}
                                        Ljud
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("Blackbox")}
                                        />
                                        Blackbox
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("Blackout")}
                                        />
                                        Mörkläggning
                                    </span>
                                </div>
                                <div className="mb-2">
                                    <label className="form-label mb-0">Övriga behov kring teknik och lokal</label>
                                    <textarea
                                        className="form-control"
                                        disabled
                                        value={application.document.library_OtherSiteRequirements ?? ""}
                                    ></textarea>
                                </div>
                                <div className="mb-2">
                                    <span className="fw-bold">Byggtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.library_BuildTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                    <span className="fw-bold">Rivtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.library_StrikeTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                </div>
                                <div>
                                    <input
                                        className="form-check-input me-1"
                                        type="checkbox"
                                        disabled
                                        checked={application.document.library_IsAssistanceRequired}
                                    />{" "}
                                    Bärhjälp krävs
                                </div>
                            </div>
                        </div>
                    )}
                    {possibleSites.includes("Outdoors") && (
                        <div className="mb-3">
                            <h5 className="mb-0">Utomhus</h5>
                            <div className="alert alert-secondary">
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Scenstorlek</label>
                                    </div>
                                    <span className="fw-bold">Bredd:</span>{" "}
                                    <span className="me-3">{application.document.outdoors_StageWidth} meter</span>
                                    <span className="fw-bold">Djup:</span>{" "}
                                    <span className="me-3">{application.document.outdoors_StageDepth} meter</span>
                                    <span className="fw-bold">Höjd:</span>{" "}
                                    <span className="me-3">{application.document.outdoors_StageHeight} meter</span>
                                </div>
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Behov av teknik</label>
                                    </div>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("PA")}
                                        />{" "}
                                        PA
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("Lighting")}
                                        />{" "}
                                        Ljus
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("Sound")}
                                        />{" "}
                                        Ljud
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("Blackbox")}
                                        />
                                        Blackbox
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("Blackout")}
                                        />
                                        Mörkläggning
                                    </span>
                                </div>
                                <div className="mb-2">
                                    <label className="form-label mb-0">Övriga behov kring teknik och lokal</label>
                                    <textarea
                                        className="form-control"
                                        disabled
                                        value={application.document.outdoors_OtherSiteRequirements ?? ""}
                                    ></textarea>
                                </div>
                                <div className="mb-2">
                                    <span className="fw-bold">Byggtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.outdoors_BuildTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                    <span className="fw-bold">Rivtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.outdoors_StrikeTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                </div>
                                <div>
                                    <input
                                        className="form-check-input me-1"
                                        type="checkbox"
                                        disabled
                                        checked={application.document.outdoors_IsAssistanceRequired}
                                    />{" "}
                                    Bärhjälp krävs
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="mb-4">
                    <h4 className="mb-2">Prisinformation</h4>
                    <div className="mb-2">
                        <input
                            className="form-check-input me-1"
                            type="checkbox"
                            disabled
                            checked={application.document.isOwnStage}
                        />{" "}
                        Evenemanget erbjuds på fast scen/egen regi
                        {application.document.isOwnStage && (
                            <div className="mb-3 container-fluid row m-0 p-0">
                                <div className="col-4 ps-0">
                                    <label className="form-label mb-0">Biljettpris</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={`${application.document.ticketPrice.toLocaleString()} kronor`}
                                        type="text"
                                    />
                                </div>
                                <div className="col-4">
                                    <label className="form-label mb-0">Antal platser</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={application.document.numberOfSeats}
                                        type="text"
                                    />
                                </div>
                                <div className="col-4 pe-0">
                                    <label className="form-label mb-0">Total biljettpris</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={`${(
                                            application.document.ticketPrice * application.document.numberOfSeats
                                        ).toLocaleString()} kronor`}
                                        type="text"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-2">
                        <input
                            className="form-check-input me-1"
                            type="checkbox"
                            disabled
                            checked={application.document.isVisiting}
                        />{" "}
                        Evenemanget erbjuds som uppsökande evenemang
                        {application.document.isVisiting && (
                            <div className="mb-3 container-fluid row m-0 p-0">
                                <div className="col-4 ps-0">
                                    <label className="form-label mb-0">Föreställningspris</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={`${application.document.occasionPrice.toLocaleString()} kronor`}
                                        type="text"
                                    />
                                </div>
                                <div className="col-4">
                                    <label className="form-label mb-0">Pris på efterföljande tillfälle samma dag</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={
                                            application.document.occasionPrice2
                                                ? `${application.document.occasionPrice2.toLocaleString()} kronor`
                                                : ""
                                        }
                                        type="text"
                                    />
                                </div>
                                <div className="col-4 pe-0">
                                    <label className="form-label mb-0">Totalt föreställningspris</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={`${(
                                            application.document.occasionPrice + application.document.occasionPrice2
                                        ).toLocaleString()} kronor`}
                                        type="text"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-2">
                        <label className="form-label mb-0">Kommentar till pris</label>
                        <textarea
                            className="form-control"
                            disabled
                            value={application.document.priceComment ?? ""}
                        ></textarea>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <a href={`/Application/${application.id}/Display`} className="btn btn-primary" target="_blank">
                    <i className="bi bi-card-text me-1"></i>Gå till intresseanmälan
                </a>
                {application.isEditable && (
                    <a href={`/Application/${application.id}/Update`} className="btn btn-primary" target="_blank">
                        <i className="bi bi-card-text me-1"></i>Redigera
                    </a>
                )}
                {application.canAdjustPrice && (
                    <button type="button" className="btn btn-primary" onClick={onShowAdjustPriceModal}>
                        <i className="bi bi-tag-fill me-1"></i>
                        Pris
                    </button>
                )}
                {application.canAdjustAgeTags && targetGroup !== TargetGroup.Total && (
                    <button type="button" className="btn btn-primary" onClick={onShowAdjustSelectionAgeTagsModal}>
                        <i className="bi bi-person-fill-gear me-1"></i>
                        Målgrupper
                    </button>
                )}
                {application.canChangeSelectionComment && (
                    <button type="button" className="btn btn-primary" onClick={onShowSelectionCommentModal}>
                        <i className="bi bi-chat-right-text-fill me-1"></i>
                        Kommentar
                    </button>
                )}
                {application.canChangeSelectionColor && (
                    <button type="button" className="btn btn-primary" onClick={onShowApplicationSelectionColorModal}>
                        <i className="bi bi-palette-fill me-1"></i>
                        Färgmarkering
                    </button>
                )}
                {application.canManageApplicationTargetGroupExclusions &&
                    targetGroup !== TargetGroup.Total &&
                    !applicationTargetGroupExclusion && (
                        <button type="button" className="btn btn-danger" onClick={() => onHideApplication(application)}>
                            <i className="bi bi-eye-slash-fill me-1"></i>
                            Dölj
                        </button>
                    )}
                {application.canManageApplicationTargetGroupExclusions &&
                    targetGroup !== TargetGroup.Total &&
                    !!applicationTargetGroupExclusion && (
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => onShowApplication(application)}
                        >
                            <i className="bi bi-eye-fill me-1"></i>
                            Visa
                        </button>
                    )}
            </Modal.Footer>
        </Modal>
    );
}

function getUrlWithProtocol(url: string) {
    if (!/^https?:\/\//i.test(url)) {
        return "http://" + url;
    }
    return url;
}

export default ApplicationDetailsModal;
