import React from "react";
import { createRoot } from "react-dom/client";
import SiteSelection from "./components/SiteSelection";

import Selection from "./components/Selection";
import ApplicationPlanning from "./components/Planning/ApplicationPlanning";
import ApplicationSiteSelection from "./components/Planning/ApplicationPlanning/ApplicationSiteSelection";
import SitePlanning from "./components/Planning/SitePlanning";
import SiteApplicationSelection from "./components/Planning/SitePlanning/SiteApplicationSelection";
import ActorContracts from "./components/ActorContracts";
import ActorContractCreation from "./components/ActorContracts/ActorContractCreation";
import SelectionSummaryMail from "./components/Selection/SelectionSummaryMail";
import SiteContracts from "./components/SiteContracts";
import SiteContractCreation from "./components/SiteContracts/SiteContractCreation";
import InternalSiteAgreement from "./components/SiteContracts/InternalSiteAgreement";
import BlockedDates from "./components/BlockedDates";

export default class Components {
    enableSelectionComponent = () => {
        const container = document.getElementById("selection-page-component");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");
            const targetGroup = container.getAttribute("data-target-group");
            const isCulturalManagement = container.dataset.isCulturalManagement === "True";

            const root = createRoot(container);
            root.render(
                <Selection
                    applicationPeriodId={Number.parseInt(applicationPeriodId)}
                    targetGroup={targetGroup}
                    isCulturalManagement={isCulturalManagement}
                />
            );
        }
    };

    enableSiteSelectionComponent = () => {
        const container = document.getElementById("site-selection-container");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");

            const root = createRoot(container);
            root.render(<SiteSelection applicationPeriodId={Number.parseInt(applicationPeriodId)} />);
        }
    };

    enableApplicationPlanningComponent = () => {
        const container = document.getElementById("application-planning-container");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");

            const root = createRoot(container);
            root.render(<ApplicationPlanning applicationPeriodId={Number.parseInt(applicationPeriodId)} />);
        }
    };

    enableApplicationSiteSelectionComponent = () => {
        const container = document.getElementById("application-site-selection-container");

        if (container) {
            const applicationId = container.getAttribute("data-application-id");
            const applicationPeriodId = container.getAttribute("data-application-period-id");
            const canManagePlanning = container.dataset["canManagePlanning"];

            const root = createRoot(container);
            root.render(
                <ApplicationSiteSelection
                    applicationId={Number.parseInt(applicationId)}
                    applicationPeriodId={Number.parseInt(applicationPeriodId)}
                    canManagePlanning={canManagePlanning === "True"}
                />
            );
        }
    };

    enableSitePlanningComponent = () => {
        const container = document.getElementById("site-planning-container");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");

            const root = createRoot(container);
            root.render(<SitePlanning applicationPeriodId={Number.parseInt(applicationPeriodId)} />);
        }
    };

    enableSiteApplicationSelectionComponent = () => {
        const container = document.getElementById("site-application-selection-container");

        if (container) {
            const siteId = container.getAttribute("data-site-id");
            const applicationPeriodId = container.getAttribute("data-application-period-id");
            const canManagePlanning = container.dataset["canManagePlanning"];

            const root = createRoot(container);
            root.render(
                <SiteApplicationSelection
                    siteId={Number.parseInt(siteId)}
                    applicationPeriodId={Number.parseInt(applicationPeriodId)}
                    canManagePlanning={canManagePlanning === "True"}
                />
            );
        }
    };

    enableActorContracts = () => {
        const container = document.getElementById("actor-contracts-container");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");

            const root = createRoot(container);
            root.render(<ActorContracts applicationPeriodId={Number.parseInt(applicationPeriodId)} />);
        }
    };

    enableActorContractCreation = () => {
        const container = document.getElementById("actor-contract-creation-container");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");
            const cultureProviderId = container.getAttribute("data-culture-provider-id");

            const root = createRoot(container);
            root.render(
                <ActorContractCreation
                    applicationPeriodId={Number.parseInt(applicationPeriodId)}
                    cultureProviderId={Number.parseInt(cultureProviderId)}
                />
            );
        }
    };

    enableSiteContracts = () => {
        const container = document.getElementById("site-contracts-container");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");

            const root = createRoot(container);
            root.render(<SiteContracts applicationPeriodId={Number.parseInt(applicationPeriodId)} />);
        }
    };

    enableSiteContractCreation = () => {
        const container = document.getElementById("site-contract-creation-container");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");
            const siteId = container.getAttribute("data-site-id");

            const root = createRoot(container);
            root.render(
                <SiteContractCreation
                    applicationPeriodId={Number.parseInt(applicationPeriodId)}
                    siteId={Number.parseInt(siteId)}
                />
            );
        }
    };

    enableSelectionSummaryMail = () => {
        const container = document.getElementById("selection-summary-mail-container");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");
            const userEmail = container.getAttribute("data-user-email");

            const root = createRoot(container);
            root.render(
                <SelectionSummaryMail
                    applicationPeriodId={Number.parseInt(applicationPeriodId)}
                    userEmail={userEmail}
                />
            );
        }
    };

    enableInternalSiteAgreement = () => {
        const container = document.getElementById("internal-site-agreement-container");

        if (container) {
            const applicationPeriodId = container.getAttribute("data-application-period-id");
            const siteId = container.getAttribute("data-site-id");

            const root = createRoot(container);
            root.render(
                <InternalSiteAgreement
                    applicationPeriodId={Number.parseInt(applicationPeriodId)}
                    siteId={Number.parseInt(siteId)}
                />
            );
        }
    };

    enableBlockedDatesEditor = () => {
        const container = document.getElementById("blocked-dates-editor-container");

        if (container) {
            const root = createRoot(container);
            root.render(<BlockedDates />);
        }
    };
}
