import React from "react";

type WarningMessageProps = {
    message: string;
};

function WarningMessage({ message }: WarningMessageProps) {
    return <div className="alert alert-warning">{message}</div>;
}

export default WarningMessage;
