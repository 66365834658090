import React, { useEffect, useState } from "react";
import { fetchCultureProvidersForContractCreation } from "../../common/apiClient";
import CultureProvider from "../../types/CultureProvider";
import { pageLoadErrorMessage } from "../../common/errorMessages";
import ActorTableRow from "./ActorTableRow";
import LoadingSpinner from "../Common/LoadingSpinner";
import ErrorMessage from "../Common/Messages/ErrorMessage";

type ActorContractsProps = {
    applicationPeriodId: number;
};

function ActorContracts({ applicationPeriodId }: ActorContractsProps) {
    const [cultureProviders, setCultureProviders] = useState<CultureProvider[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const cultureProvidersFetchResult = await fetchCultureProvidersForContractCreation(applicationPeriodId);

            if (cultureProvidersFetchResult.errorMessage) {
                console.error(cultureProvidersFetchResult.errorMessage);
                setErrorMessage(pageLoadErrorMessage);
                setIsLoading(false);
                return;
            }
            setCultureProviders(cultureProvidersFetchResult.value);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (errorMessage) {
        return <ErrorMessage message={errorMessage} />;
    }

    const cultureProviderTableRows: JSX.Element[] = cultureProviders.map((c) => (
        <ActorTableRow
            cultureProvider={c}
            key={c.id}
            onTableRowClick={() => tableRowClickHandler(applicationPeriodId, c.id)}
        />
    ));

    return (
        <div className="container mt-2">
            <h2>Avtalsunderlag - aktörer</h2>
            <div className="border rounded" style={{ overflow: "hidden" }}>
                <table className="table table-hover-effect">
                    <thead>
                        <tr>
                            <th>Aktör</th>
                        </tr>
                    </thead>
                    <tbody>{cultureProviderTableRows}</tbody>
                </table>
            </div>
        </div>
    );
}

function tableRowClickHandler(applicationPeriodId: number, cultureProviderId: number) {
    window.location.href = `/ActorContracts/${applicationPeriodId}/contractCreation/${cultureProviderId}`;
}

export default ActorContracts;
