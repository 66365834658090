
export default class SiteCreateUpdate {

    enableSitePriceSelection = () => {

        const secondarySiteTypeSelect = document.getElementById("secondary-site-type") as HTMLInputElement;
        secondarySiteTypeSelect.addEventListener("change", this.checkSitePriceSelection);
        this.checkSitePriceSelection();
    }; 

    checkSitePriceSelection() {
        const externalPriceOptions = document.getElementById("external-price-options") as HTMLInputElement;
        const secondarySiteTypeSelect = document.getElementById("secondary-site-type") as HTMLInputElement;
            if (secondarySiteTypeSelect.value === "External") {
                externalPriceOptions.style.display = "block";
            } else {
                externalPriceOptions.style.display = "none";
            }
        }
}