import React from "react";
import Application from "../../../types/Application";
import { Accordion } from "react-bootstrap";

type ApplicationDetailsAccordionItemProps = {
    application: Application;
};

function ApplicationDetailsAccordionItem({ application }: ApplicationDetailsAccordionItemProps) {
    const possibleSites = application.document.possibleSiteTypes;
    const schoolSiteRequirements = application.document.school_SiteRequirements;
    const librarySiteRequirements = application.document.library_SiteRequirements;
    const outdoorsSiteRequirements = application.document.outdoors_SiteRequirements;

    return (
        <Accordion.Item eventKey="ApplicationDetails">
            <Accordion.Header>
                <span className="fw-bold">Information om evenemanget</span>
            </Accordion.Header>
            <Accordion.Body>
                <section className="mb-4">
                    <h4 className="mb-2">Evenemangsinformation</h4>
                    <div className="mb-2 container-fluid row m-0 p-0">
                        <div className="col-6 ps-0">
                            <label className="form-label mb-0">Aktör</label>
                            <input className="form-control" disabled value={application.actorName} type="text" />
                        </div>
                        <div className="col-6 pe-0">
                            <label className="form-label mb-0">Evenemang</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.document.eventName}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="mb-2 container-fluid row m-0 p-0">
                        <div className="col-6 ps-0">
                            <label className="form-label mb-0">Målgrupp</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.groupedAgeTags.join(", ")}
                                type="text"
                            />
                        </div>
                        <div className="col-6 pe-0">
                            <label className="form-label mb-0">Konstformer</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.document.tags.join(", ")}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="mb-2 container-fluid row m-0 p-0">
                        <div className="col-6 ps-0">
                            <label className="form-label mb-0">Evenemangets längd</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.document.eventDuration.split(":").slice(0, 2).join(":")}
                                type="text"
                            />
                        </div>
                        <div className="col-6 pe-0">
                            <label className="form-label mb-0">Maxpublik (inkluderat vuxna)</label>
                            <input
                                className="form-control"
                                disabled
                                value={application.document.maxNumberOfSeats}
                                type="text"
                            />
                        </div>
                    </div>
                </section>

                <section className="mb-4">
                    <h4 className="mb-2">Spelplats</h4>
                    <div className="d-flex mb-3">
                        <span className="me-5">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                disabled
                                checked={possibleSites.includes("OwnStage")}
                            />{" "}
                            Egen scen
                        </span>
                        <span className="me-5">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                disabled
                                checked={possibleSites.includes("School")}
                            />
                            Klassrum/Förskola
                        </span>
                        <span className="me-5">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                disabled
                                checked={possibleSites.includes("Library")}
                            />
                            Kulturhus/Bibliotek
                        </span>
                        <span className="me-5">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                disabled
                                checked={possibleSites.includes("Outdoors")}
                            />
                            Utomhus
                        </span>
                    </div>
                    {possibleSites.includes("School") && (
                        <div className="mb-3">
                            <h5 className="mb-0">Klassrum/Förskola</h5>
                            <div className="alert alert-secondary">
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Scenstorlek</label>
                                    </div>
                                    <span className="fw-bold">Bredd:</span>{" "}
                                    <span className="me-3">{application.document.school_StageWidth} meter</span>
                                    <span className="fw-bold">Djup:</span>{" "}
                                    <span className="me-3">{application.document.school_StageDepth} meter</span>
                                    <span className="fw-bold">Höjd:</span>{" "}
                                    <span className="me-3">{application.document.school_StageHeight} meter</span>
                                </div>
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Behov av teknik</label>
                                    </div>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("PA")}
                                        />{" "}
                                        PA
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("Lighting")}
                                        />{" "}
                                        Ljus
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("Sound")}
                                        />{" "}
                                        Ljud
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("Blackbox")}
                                        />
                                        Blackbox
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={schoolSiteRequirements.includes("Blackout")}
                                        />
                                        Mörkläggning
                                    </span>
                                </div>
                                <div className="mb-2">
                                    <label className="form-label mb-0">Övriga behov kring teknik och lokal</label>
                                    <textarea
                                        className="form-control"
                                        disabled
                                        value={application.document.school_OtherSiteRequirements ?? ""}
                                    ></textarea>
                                </div>
                                <div className="mb-2">
                                    <span className="fw-bold">Byggtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.school_BuildTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                    <span className="fw-bold">Rivtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.school_StrikeTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                </div>
                                <div>
                                    <input
                                        className="form-check-input me-1"
                                        type="checkbox"
                                        disabled
                                        checked={application.document.school_IsAssistanceRequired}
                                    />{" "}
                                    Bärhjälp krävs
                                </div>
                            </div>
                        </div>
                    )}
                    {possibleSites.includes("Library") && (
                        <div className="mb-3">
                            <h5 className="mb-0">Kulturhus/Bibliotek</h5>
                            <div className="alert alert-secondary">
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Scenstorlek</label>
                                    </div>
                                    <span className="fw-bold">Bredd:</span>{" "}
                                    <span className="me-3">{application.document.library_StageWidth} meter</span>
                                    <span className="fw-bold">Djup:</span>{" "}
                                    <span className="me-3">{application.document.library_StageDepth} meter</span>
                                    <span className="fw-bold">Höjd:</span>{" "}
                                    <span className="me-3">{application.document.library_StageHeight} meter</span>
                                </div>
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Behov av teknik</label>
                                    </div>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("PA")}
                                        />{" "}
                                        PA
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("Lighting")}
                                        />{" "}
                                        Ljus
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("Sound")}
                                        />{" "}
                                        Ljud
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("Blackbox")}
                                        />
                                        Blackbox
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={librarySiteRequirements.includes("Blackout")}
                                        />
                                        Mörkläggning
                                    </span>
                                </div>
                                <div className="mb-2">
                                    <label className="form-label mb-0">Övriga behov kring teknik och lokal</label>
                                    <textarea
                                        className="form-control"
                                        disabled
                                        value={application.document.library_OtherSiteRequirements ?? ""}
                                    ></textarea>
                                </div>
                                <div className="mb-2">
                                    <span className="fw-bold">Byggtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.library_BuildTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                    <span className="fw-bold">Rivtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.library_StrikeTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                </div>
                                <div>
                                    <input
                                        className="form-check-input me-1"
                                        type="checkbox"
                                        disabled
                                        checked={application.document.library_IsAssistanceRequired}
                                    />{" "}
                                    Bärhjälp krävs
                                </div>
                            </div>
                        </div>
                    )}
                    {possibleSites.includes("Outdoors") && (
                        <div className="mb-3">
                            <h5 className="mb-0">Utomhus</h5>
                            <div className="alert alert-secondary">
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Scenstorlek</label>
                                    </div>
                                    <span className="fw-bold">Bredd:</span>{" "}
                                    <span className="me-3">{application.document.outdoors_StageWidth} meter</span>
                                    <span className="fw-bold">Djup:</span>{" "}
                                    <span className="me-3">{application.document.outdoors_StageDepth} meter</span>
                                    <span className="fw-bold">Höjd:</span>{" "}
                                    <span className="me-3">{application.document.outdoors_StageHeight} meter</span>
                                </div>
                                <div className="mb-2">
                                    <div>
                                        <label className="form-label mb-0">Behov av teknik</label>
                                    </div>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("PA")}
                                        />{" "}
                                        PA
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("Lighting")}
                                        />{" "}
                                        Ljus
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("Sound")}
                                        />{" "}
                                        Ljud
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("Blackbox")}
                                        />
                                        Blackbox
                                    </span>
                                    <span className="me-5">
                                        <input
                                            className="form-check-input me-1"
                                            type="checkbox"
                                            disabled
                                            checked={outdoorsSiteRequirements.includes("Blackout")}
                                        />
                                        Mörkläggning
                                    </span>
                                </div>
                                <div className="mb-2">
                                    <label className="form-label mb-0">Övriga behov kring teknik och lokal</label>
                                    <textarea
                                        className="form-control"
                                        disabled
                                        value={application.document.outdoors_OtherSiteRequirements ?? ""}
                                    ></textarea>
                                </div>
                                <div className="mb-2">
                                    <span className="fw-bold">Byggtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.outdoors_BuildTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                    <span className="fw-bold">Rivtid:</span>{" "}
                                    <span className="me-3">
                                        {application.document.outdoors_StrikeTime.split(":").slice(0, 2).join(":")}
                                    </span>
                                </div>
                                <div>
                                    <input
                                        className="form-check-input me-1"
                                        type="checkbox"
                                        disabled
                                        checked={application.document.outdoors_IsAssistanceRequired}
                                    />{" "}
                                    Bärhjälp krävs
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default ApplicationDetailsAccordionItem;
