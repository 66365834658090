import Swal from "sweetalert2";
import { getRequestVerificationToken } from "../../Content/ts/common";

export default class SitesIndex {
    enableDeleteSites() {
        const deleteSiteButtons = document.querySelectorAll<HTMLButtonElement>("button[data-delete-site]");
        const requestVerificationToken = getRequestVerificationToken();

        deleteSiteButtons.forEach((button) => {
            const url = button.dataset.url;
            const siteName = button.dataset.siteName;
            const roomName = button.dataset.roomName;

            button.onclick = () => {
                Swal.fire({
                    icon: "warning",
                    text: `Är du säker på att du vill ta bort ${siteName} - ${roomName}?`,
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (!result.isConfirmed) return;

                    const config: RequestInit = {
                        method: "DELETE",
                        headers: { RequestVerificationToken: requestVerificationToken }
                    };

                    const response = await fetch(url, config);

                    if (response.ok) {
                        window.location.reload();
                    } else {
                        const data = await response.text();
                        alert(data);
                    }
                });
            };
        });
    }
}
