import React from "react";
import { SitePlanningStatus } from "../../../common/enums";

export type SitePlanningFilterOptions = {
    searchText?: string;
    sitePlanningStatus?: SitePlanningStatus;
};

type SitePlanningFilterProps = {
    selectedFilterOptions: SitePlanningFilterOptions;
    onSelectedFilterOptionsChange: (updatedProperty: keyof SitePlanningFilterOptions, updatedValue: any) => void;
};

const sitePlanningStatusOptions = Object.values(SitePlanningStatus).map((status) => (
    <option key={status} value={status}>
        {status}
    </option>
));

function SitePlanningFilter({ selectedFilterOptions, onSelectedFilterOptionsChange }: SitePlanningFilterProps) {
    return (
        <div className="row mb-3">
            <div className="col-md-3">
                <label className="form-label mb-0" htmlFor="search-input">
                    Fritextsök
                </label>
                <input
                    className="form-control"
                    type="text"
                    id="search-input"
                    placeholder="Sök på plats eller lokalnamn"
                    value={
                        selectedFilterOptions && selectedFilterOptions.searchText
                            ? selectedFilterOptions.searchText
                            : ""
                    }
                    onChange={(event) => onSelectedFilterOptionsChange("searchText", event.target.value)}
                />
            </div>
            <div className="col-md-3">
                <label className="form-label mb-0" htmlFor="application-planning-status">
                    Programläggningsstatus
                </label>
                <select
                    className="form-select"
                    id="application-planning-status"
                    onChange={(event) => onSelectedFilterOptionsChange("sitePlanningStatus", event.target.value)}
                >
                    <option value="">- Alla -</option>
                    {sitePlanningStatusOptions}
                </select>
            </div>
        </div>
    );
}

export default SitePlanningFilter;
