import React from "react";
import { CityArea } from "../../../common/enums";

export type ApplicationSiteSelectionFilterOptions = {
    cityArea: CityArea;
    searchText: string;
};

type ApplicationSiteSelectionFilterProps = {
    selectedFilterOptions: ApplicationSiteSelectionFilterOptions;
    onSelectedFilterOptionsChange: (updatedProperty: keyof ApplicationSiteSelectionFilterOptions, updatedValue: any) => void;
};

const cityAreaOptions = Object.entries(CityArea).map((entry) => (
    <option key={entry[0]} value={entry[0]}>
        {entry[1]}
    </option>
));

function ApplicationSiteSelectionFilter({
    selectedFilterOptions,
    onSelectedFilterOptionsChange
}: ApplicationSiteSelectionFilterProps) {
    return (
        <div className="row mb-2">
            <div className="col-md-3 form-group">
                <label className="form-label m-0">Stadsområde</label>
                <select
                    className="form-select"
                    value={selectedFilterOptions ? selectedFilterOptions.cityArea : ""}
                    onChange={(event) => onSelectedFilterOptionsChange("cityArea", event.target.value)}
                >
                    <option value={""}>- Alla stadsområden -</option>
                    {cityAreaOptions}
                </select>
            </div>
            <div className="col-md-3 form-group">
                <label className="form-label m-0">Fritextsök</label>
                <input
                    className="form-control"
                    placeholder="Sök på plats eller lokalnamn"
                    value={selectedFilterOptions ? selectedFilterOptions.searchText : ""}
                    onChange={(event) => onSelectedFilterOptionsChange("searchText", event.target.value)}
                ></input>
            </div>
        </div>
    );
}

export default ApplicationSiteSelectionFilter;
