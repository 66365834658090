import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import Application from "../../types/Application";
import { HexColor, TargetGroup } from "../../common/enums";
import ApplicationSelectionColor from "../../types/ApplicationSelectionColor";
import { deleteApplicationSelectionColor, postApplicationSelectionColor } from "../../common/apiClient";
import { getApplicationSelectionColorByTargetGroup } from "../../common/applicationUtils";

type ApplicationSelectionColorModalProps = {
    application: Application;
    targetGroup: TargetGroup;
    isCulturalManagement: boolean;
    show: boolean;
    onHide: () => void;
    onApplicationChange: (updatedApplication: Application) => void;
    onError: (errorMessage: string) => void;
};

const allColors = Object.values(HexColor);

function ApplicationSelectionColorModal({
    application,
    targetGroup,
    isCulturalManagement,
    show,
    onHide,
    onApplicationChange,
    onError
}: ApplicationSelectionColorModalProps) {
    const selectedColor = useMemo(
        () => getApplicationSelectionColorByTargetGroup(application, targetGroup, isCulturalManagement),
        [application, targetGroup, isCulturalManagement]
    );

    const colorButtonClickHandler = async (hexColor: HexColor) => {
        if (selectedColor && selectedColor.hexColor === hexColor) {
            const updatedApplication: Application = {
                ...application,
                applicationSelectionColors: [
                    ...application.applicationSelectionColors.filter(
                        (asc) => asc.targetGroup !== targetGroup || asc.isCulturalManagement !== isCulturalManagement
                    )
                ]
            };

            onApplicationChange(updatedApplication);

            const deleteResult = await deleteApplicationSelectionColor(selectedColor.id);

            if (deleteResult.errorMessage) {
                console.error(deleteResult.errorMessage);
                onError("Något gick fel när färgmarkeringen togs bort. Vänligen uppdatera sidan och försök igen.");
            }

            return;
        }

        const applicationSelectionColor: ApplicationSelectionColor = {
            applicationId: application.id,
            targetGroup: targetGroup,
            isCulturalManagement: isCulturalManagement,
            hexColor: hexColor
        };

        application.applicationSelectionColors = [
            ...application.applicationSelectionColors.filter(
                (asc) => asc.targetGroup !== targetGroup || asc.isCulturalManagement !== isCulturalManagement
            ),
            applicationSelectionColor
        ];

        onApplicationChange({...application});

        const postResult = await postApplicationSelectionColor(
            hexColor,
            application.id,
            targetGroup,
            isCulturalManagement
        );

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            onError("Något gick fel när färgmarkeringen sparades. vänligen ladda om sidan och försök igen.");
            return;
        }

        application.applicationSelectionColors = application.applicationSelectionColors.map((asc) =>
            asc.targetGroup === targetGroup && asc.isCulturalManagement === isCulturalManagement
                ? { ...asc, id: postResult.value }
                : asc
        );

        onApplicationChange({...application});
    };

    const colorOptions = allColors.map((hexColor) => (
        <button
            className={`application-selection-color-modal__button ${
                selectedColor && hexColor === selectedColor.hexColor ? "selected" : ""
            }`}
            style={{ backgroundColor: hexColor }}
            key={hexColor}
            onClick={() => colorButtonClickHandler(hexColor)}
        >
            {" "}
        </button>
    ));

    return (
        <Modal show={show} size="lg" onHide={onHide}>
            <Modal.Header closeButton>
                <h4>Färgmarkering för "{application.document.eventName}" </h4>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-wrap justify-content-center">{colorOptions}</div>
            </Modal.Body>
        </Modal>
    );
}

export default ApplicationSelectionColorModal;
