import Swal from "sweetalert2";

export default class ApplicationDisplay {
    enableUpdatePossibleOccasionDates() {
        const saveButton = document.querySelector("button[data-save-possible-occasion-dates]") as HTMLButtonElement;

        if (!saveButton) return;

        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const datePicker = saveButton.parentElement.querySelector("input");
        const url = saveButton.dataset.url;

        saveButton.onclick = async () => {
            const selectedDates = datePicker.value.split(",").map((dateString) => new Date(dateString));

            const headers = { RequestVerificationToken: antiforgeryInput.value, "content-type": "application/json" };
            const body = JSON.stringify(selectedDates);
            const response = await fetch(url, { method: "PUT", headers, body });
            if (response.ok) {
                Swal.fire({
                    text: "De möjliga datumen för evenemanget har uppdaterats.",
                    icon: "success"
                });
            } else {
                const data = await response.text();
                alert(data);
            }
        };
    }
}
