import React from "react";

type InfoMessageProps = {
    message: string;
};

function InfoMessage({ message }: InfoMessageProps) {
    return <div className="alert alert-info">{message}</div>;
}

export default InfoMessage;
