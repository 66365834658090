import React, { useEffect, useState } from "react";
import InformationPanel from "../InformationPanel";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import Application from "../../types/Application";
import {
    applicationBelongsToTargetGroup,
    getAdjustedAgeTagsByTargetGroup,
    getApplicationTargetGroupExclusion
} from "../../common/applicationUtils";
import { TargetGroup } from "../../common/enums";
import SelectionTableRow from "./SelectionTableRow";
import ApplicationDetailsModal from "./ApplicationDetailsModal";
import LoadingSpinner from "../Common/LoadingSpinner";
import Filter, { SelectedFilterOptions } from "./Filter";
import AdjustPriceModal from "./AdjustPriceModal";
import SelectionOverviewAccordion from "./SelectionOverviewAccordion";
import SelectionCommentModal from "./SelectionCommentModal";
import ApplicationSelectionColorModal from "./ApplicationSelectionColorModal";
import ApplicationTargetGroupExclusion from "../../types/ApplicationTargetGroupExclusion";
import { deleteApplicationTargetGroupExclusion, postApplicationTargetGroupExclusion } from "../../common/apiClient";
import AdjustSelectionAgeTagsModal from "./AdjustSelectionAgeTagsModal";

type SelectionPageProps = {
    applicationPeriodId: number;
    targetGroup: string;
    isCulturalManagement: boolean;
};

export default function Selection({ applicationPeriodId, targetGroup, isCulturalManagement }: SelectionPageProps) {
    const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod>();
    const [errorMessage, setErrorMessage] = useState("");
    const [modalApplication, setModalApplication] = useState<Application>();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<SelectedFilterOptions>();
    const [showApplicationDetailsModal, setShowApplicationDetailsModal] = useState(false);
    const [showAdjustPriceModal, setShowAdjustPriceModal] = useState(false);
    const [showSelectionCommentModal, setShowSelectionCommentModal] = useState(false);
    const [showApplicationSelectionColorModal, setShowApplicationSelectionColorModal] = useState(false);
    const [showAdjustSelectionAgeTagsModal, setShowAdjustSelectionAgeTagsModal] = useState(false);

    const filterStorageKey = "selectedFilterOptions";

    useEffect(() => {
        async function fetchApplicationPeriod(applicationPeriodId: number) {
            try {
                const fetchResult = await fetch(`/api/applicationPeriod/${applicationPeriodId}/selectionData`, {
                    cache: "no-store"
                });

                if (!fetchResult.ok) {
                    setErrorMessage("Sidan kunde inte laddas. Om problemet kvarstår vänligen kontakta support.");
                    setIsLoading(false);
                    return;
                }

                const fetchedApplicationPeriod: ApplicationPeriod = await fetchResult.json();

                setApplicationPeriod(fetchedApplicationPeriod);
                setModalApplication(fetchedApplicationPeriod.applications[0]);
            } catch (e) {
                setErrorMessage("Sidan kunde inte laddas. Om problemet kvarstår vänligen kontakta support.");
            }

            setIsLoading(false);
        }
        fetchApplicationPeriod(applicationPeriodId);

        const storedFilterOptions = JSON.parse(localStorage.getItem(filterStorageKey));
        setSelectedFilterOptions(storedFilterOptions);
    }, []);

    if (isLoading) {
        return (
            <div className="w-100">
                <LoadingSpinner />
            </div>
        );
    }

    function applicationChangeHandler(updatedApplication: Application) {
        setApplicationPeriod((prevState) => {
            const updatedApplications = prevState.applications.map((a) =>
                a.id === updatedApplication.id ? updatedApplication : a
            );

            return { ...prevState, applications: updatedApplications };
        });

        if (modalApplication.id === updatedApplication.id) {
            setModalApplication({ ...updatedApplication });
        }
    }

    function showApplicationDetailsHandler(application: Application) {
        setModalApplication(application);
        setShowApplicationDetailsModal(true);
    }

    function showAdjustPriceModalHandler() {
        setShowApplicationDetailsModal(false);
        setShowAdjustPriceModal(true);
    }

    function hideAdjustPriceModalHandler() {
        setShowAdjustPriceModal(false);
        setShowApplicationDetailsModal(true);
    }

    function showSelectionCommentModalHandler() {
        setShowSelectionCommentModal(true);
        setShowApplicationDetailsModal(false);
    }

    function hideSelectionCommentModalHandler() {
        setShowSelectionCommentModal(false);
        setShowApplicationDetailsModal(true);
    }

    function showApplicationSelectionColorModalHandler() {
        setShowApplicationSelectionColorModal(true);
        setShowApplicationDetailsModal(false);
    }

    function hideApplicationSelectionColorModalHandler() {
        setShowApplicationSelectionColorModal(false);
        setShowApplicationDetailsModal(true);
    }

    function showAdjustSelectionAgeTagModalHandler() {
        setShowApplicationDetailsModal(false);
        setShowAdjustSelectionAgeTagsModal(true);
    }

    function hideAdjustSelectionAgeTagModalHandler() {
        setShowAdjustSelectionAgeTagsModal(false);
        setShowApplicationDetailsModal(true);
    }

    function errorMessageHandler(errorMessage: string) {
        setErrorMessage(errorMessage);
    }

    function onFilterChange(updatedProperty: keyof SelectedFilterOptions, updatedValue: any) {
        const updatedFilterOptions = { ...selectedFilterOptions, [updatedProperty]: updatedValue };
        localStorage.setItem(filterStorageKey, JSON.stringify(updatedFilterOptions));

        setSelectedFilterOptions(updatedFilterOptions);
    }

    async function hideApplicationHandler(application: Application) {
        const tempId = -1;

        const exclusion: ApplicationTargetGroupExclusion = {
            id: tempId,
            applicationId: application.id,
            targetGroup: targetGroup as TargetGroup,
            isCulturalManagement: isCulturalManagement
        };

        const updatedApplication = {
            ...application,
            applicationTargetGroupExclusions: [...application.applicationTargetGroupExclusions, exclusion]
        };

        applicationChangeHandler(updatedApplication);

        const postResult = await postApplicationTargetGroupExclusion(
            application.id,
            targetGroup as TargetGroup,
            isCulturalManagement
        );

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            errorMessageHandler("Något gick fel när intresseanmälan doldes. Vänligen uppdatera sidan och försök igen.");
        }

        const exclusionWithId: ApplicationTargetGroupExclusion = { ...exclusion, id: postResult.value };

        applicationChangeHandler({
            ...updatedApplication,
            applicationTargetGroupExclusions: updatedApplication.applicationTargetGroupExclusions.map((a) =>
                a.id === tempId ? exclusionWithId : a
            )
        });
    }

    async function showApplicationHandler(application: Application) {
        const exclusion = getApplicationTargetGroupExclusion(
            application,
            targetGroup as TargetGroup,
            isCulturalManagement
        );

        applicationChangeHandler({
            ...application,
            applicationTargetGroupExclusions: application.applicationTargetGroupExclusions.filter(
                (a) => a.id !== exclusion.id
            )
        });

        const deleteResult = await deleteApplicationTargetGroupExclusion(exclusion.id);

        if (deleteResult.errorMessage) {
            console.error(deleteResult.errorMessage);
            errorMessageHandler(
                "Något gick fel när intresseanmälan skulle visas. Vänligen uppdatera sidan och försök igen."
            );
        }
    }

    const currentApplications =
        selectedFilterOptions && selectedFilterOptions.showHiddenApplications
            ? applicationPeriod.applications
            : applicationPeriod.applications.filter((a) =>
                  applicationBelongsToTargetGroup(a, targetGroup as TargetGroup, isCulturalManagement)
              );

    let filteredApplications: Application[] = [];

    if (!selectedFilterOptions) {
        filteredApplications = currentApplications;
    } else {
        const selectedActorName = selectedFilterOptions.actorName;
        const selectedTag = selectedFilterOptions.tag;
        const selectedAgeTag = selectedFilterOptions.ageTag;
        const selectedSiteType = selectedFilterOptions.siteType;
        const showHiddenApplications = selectedFilterOptions.showHiddenApplications;

        filteredApplications = currentApplications
            .filter((a) => (selectedActorName ? a.actorName === selectedActorName : true))
            .filter((a) => (selectedTag ? a.document.tags.includes(selectedTag) : true))
            .filter((a) => (selectedAgeTag ? a.document.ageTags.includes(selectedAgeTag) : true))
            .filter((a) => (selectedSiteType ? a.document.possibleSiteTypes.includes(selectedSiteType) : true))
            .filter((a) =>
                showHiddenApplications
                    ? true
                    : !getApplicationTargetGroupExclusion(a, targetGroup as TargetGroup, isCulturalManagement)
            );
    }

    const tableRows = filteredApplications.map((a) => (
        <SelectionTableRow
            application={a}
            targetGroup={targetGroup}
            key={a.id}
            onError={setErrorMessage}
            onApplicationChange={applicationChangeHandler}
            onShowApplicationDetailsModal={showApplicationDetailsHandler}
            isCulturalManagement={isCulturalManagement}
        />
    ));

    return (
        <>
            {targetGroup === TargetGroup.Total && (
                <SelectionOverviewAccordion
                    applications={applicationPeriod.applications}
                    isCulturalManagement={isCulturalManagement}
                />
            )}
            <Filter
                applications={currentApplications}
                applicationPeriod={applicationPeriod}
                onSelectedFilterOptionsChange={onFilterChange}
                selectedFilterOptions={selectedFilterOptions}
                targetGroup={targetGroup as TargetGroup}
                isCulturalManagement={isCulturalManagement}
            />

            {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : ""}
            <div className="card mt-3">
                <table
                    className={`table table-hover-effect ${isCulturalManagement ? "cultural-management-selection" : ""}`}
                >
                    <thead>
                        <tr>
                            <th>Aktör</th>
                            <th>Evenemang</th>
                            <th className="text-end">Platser</th>
                            <th className="text-end">Pris</th>
                            <th>Konstform</th>
                            <th>Målgrupp</th>
                            {targetGroup != TargetGroup.Total && <th style={{ width: "128px" }}>Tillfällen</th>}
                            <th style={{ width: "128px" }}>Tillfällen totalt</th>
                            <th style={{ width: "128px" }}>Pris totalt</th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            </div>
            <ApplicationDetailsModal
                application={modalApplication}
                show={showApplicationDetailsModal}
                onHide={() => setShowApplicationDetailsModal(false)}
                onShowAdjustPriceModal={showAdjustPriceModalHandler}
                onShowSelectionCommentModal={showSelectionCommentModalHandler}
                onShowApplicationSelectionColorModal={showApplicationSelectionColorModalHandler}
                onHideApplication={hideApplicationHandler}
                onShowApplication={showApplicationHandler}
                isCulturalManagement={isCulturalManagement}
                targetGroup={targetGroup as TargetGroup}
                onShowAdjustSelectionAgeTagsModal={showAdjustSelectionAgeTagModalHandler}
            />
            <AdjustPriceModal
                application={modalApplication}
                show={showAdjustPriceModal}
                onHide={hideAdjustPriceModalHandler}
                onApplicationChange={applicationChangeHandler}
                onError={errorMessageHandler}
            />
            <SelectionCommentModal
                application={modalApplication}
                show={showSelectionCommentModal}
                onHide={hideSelectionCommentModalHandler}
                onApplicationChange={applicationChangeHandler}
                onError={errorMessageHandler}
                targetGroup={targetGroup as TargetGroup}
                isCulturalManagement={isCulturalManagement}
            />
            <InformationPanel
                applicationPeriod={applicationPeriod}
                targetGroup={targetGroup}
                isCulturalManagement={isCulturalManagement}
            />
            <ApplicationSelectionColorModal
                application={modalApplication}
                isCulturalManagement={isCulturalManagement}
                onApplicationChange={applicationChangeHandler}
                onHide={hideApplicationSelectionColorModalHandler}
                show={showApplicationSelectionColorModal}
                targetGroup={targetGroup as TargetGroup}
                onError={errorMessageHandler}
            />
            <AdjustSelectionAgeTagsModal
                application={modalApplication}
                isCulturalManagement={isCulturalManagement}
                onApplicationChange={applicationChangeHandler}
                onHide={hideAdjustSelectionAgeTagModalHandler}
                onError={errorMessageHandler}
                show={showAdjustSelectionAgeTagsModal}
                targetGroup={targetGroup as TargetGroup}
            />
        </>
    );
}
