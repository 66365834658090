import { AgeTag } from "./enums";

export async function copyRichText(htmlString: string) {
    const htmlBlob = new Blob([htmlString], { type: "text/html" });
    const data = new ClipboardItem({ "text/html": htmlBlob });
    await navigator.clipboard.write([data]);
}

export function toGroupedAgeTags(tagNames: AgeTag[]): string[] {
    const preSchoolTags = tagNames
        .filter((t) => t.endsWith(" år"))
        .map((t) => ({ name: t, sortOrder: Number.parseInt(t.replace(" år", "")) - 8 }));

    const schoolTags = tagNames
        .filter((t) => t.startsWith("Åk") || t === "F-klass")
        .map((t) => ({ name: t, sortOrder: Number.parseInt(t.replace("Åk", "").replace("F-klass", "-1")) }));

    const highSchoolTags = tagNames
        .filter((t) => t.startsWith("Gymnasie Åk"))
        .map((t) => ({ name: t, sortOrder: Number.parseInt(t.replace("Gymnasie Åk", "")) + 10 }));

    const otherTags = tagNames
        .filter((t) => t == "Särskola" || t == "Anpassad skola" || t == "Fritidshem" || t == "SFI")
        .map((t) => ({
            name: t,
            sortOrder: t == "Särskola" ? 100 : t == "Anpassad skola" ? 100 : t == "Fritidshem" ? 200 : 300
        }));

    const allTags = Array.from(new Set([...preSchoolTags, ...schoolTags, ...highSchoolTags, ...otherTags])).sort(
        (a, b) => a.sortOrder - b.sortOrder
    );


    const groups: string[] = [];
    let currentGroup: string[] = [];

    for (let index = 0; index < allTags.length; index++) {
        const isLastTag = index === allTags.length - 1;

        const currentTag = allTags[index];
        const nextTag = isLastTag ? null : allTags[index + 1];

        const isLastInGroup = nextTag === null || currentTag.sortOrder + 1 !== nextTag.sortOrder;

        currentGroup.push(currentTag.name);

        if (isLastInGroup) {
            const firstTag = currentGroup[0];
            const lastTag = currentGroup[currentGroup.length - 1];
            const name =
                firstTag == lastTag
                    ? firstTag
                    : `${firstTag.replace(" år", "")}-${lastTag
                          .replace("Gymnasie åk", "")
                          .replace("Åk", "")
                          .replace("Födda 20", "")}`;
            groups.push(name);
            currentGroup = [];
        }
    }

    return groups;
}
