import React from "react";
import { Modal } from "react-bootstrap";
import Application from "../../types/Application";
import { AgeTag, TargetGroup } from "../../common/enums";
import InfoMessage from "../Common/Messages/InfoMessage";
import {
    getAdjustedAgeTagsByTargetGroup,
    getAgeTagsByTargetGroup,
    targetGroupNames
} from "../../common/applicationUtils";
import { deleteAdjustedSelectionAgeTag, postAdjustedSelectionAgeTag } from "../../common/apiClient";
import AdjustedSelectionAgeTag from "../../types/AdjustedSelectionAgeTag";
import { toGroupedAgeTags } from "../../common/stringUtils";

type AdjustSelectionAgeTagsModalProps = {
    show: boolean;
    onHide: () => void;
    application: Application;
    targetGroup: TargetGroup;
    isCulturalManagement: boolean;
    onApplicationChange: (updatedApplication: Application) => void;
    onError: (errorMessage: string) => void;
};

function AdjustSelectionAgeTagsModal({
    show,
    onHide,
    application,
    targetGroup,
    isCulturalManagement,
    onApplicationChange,
    onError
}: AdjustSelectionAgeTagsModalProps) {
    const checkboxChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const affectedAgeTag = e.target.value;

        const existingAdjustedSelectionAgeTag = application.adjustedSelectionAgeTags.find(
            (a) =>
                a.ageTag === affectedAgeTag &&
                a.isCulturalManagement === isCulturalManagement &&
                a.adjustedIn === targetGroup
        );

        if (existingAdjustedSelectionAgeTag) {
            const updatedApplication = {
                ...application,
                adjustedSelectionAgeTags: application.adjustedSelectionAgeTags.filter(
                    (a) => a.id !== existingAdjustedSelectionAgeTag.id
                )
            };

            onApplicationChange(updatedApplication);

            const deleteResult = await deleteAdjustedSelectionAgeTag(existingAdjustedSelectionAgeTag.id);

            if (deleteResult.errorMessage) {
                console.error(deleteResult.errorMessage);
                onError(deleteResult.errorMessage);
            }

            return;
        }

        const tempId = -1;

        const isChecked = e.target.checked;

        const newAdjustedSelectionAgeTag: AdjustedSelectionAgeTag = {
            applicationId: application.id,
            ageTag: affectedAgeTag as AgeTag,
            id: tempId,
            isCulturalManagement: isCulturalManagement,
            isTargeted: isChecked,
            adjustedIn: targetGroup
        };

        const updatedApplication = {
            ...application,
            adjustedSelectionAgeTags: [...application.adjustedSelectionAgeTags, newAdjustedSelectionAgeTag]
        };

        onApplicationChange(updatedApplication);

        const postResult = await postAdjustedSelectionAgeTag(newAdjustedSelectionAgeTag);

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            onError(postResult.errorMessage);
            return;
        }

        const createdId = postResult.value;

        const adjustedSelectionAgeTagWithId: AdjustedSelectionAgeTag = { ...newAdjustedSelectionAgeTag, id: createdId };

        onApplicationChange({
            ...updatedApplication,
            adjustedSelectionAgeTags: [...application.adjustedSelectionAgeTags, adjustedSelectionAgeTagWithId]
        });
    };

    const ageTagsForTargetGroup = getAgeTagsByTargetGroup(targetGroup);

    let selectedAgeTags = [...application.document.ageTags];

    application.adjustedSelectionAgeTags.forEach((asat) => {
        if (asat.isCulturalManagement !== isCulturalManagement || asat.adjustedIn !== targetGroup) return;

        if (!asat.isTargeted) {
            selectedAgeTags = selectedAgeTags.filter((a) => a !== asat.ageTag);
        } else {
            selectedAgeTags.push(asat.ageTag);
        }
    });

    const ageTagOptions = ageTagsForTargetGroup.map((ageTag) => {
        const checked = selectedAgeTags.some((applicationAgeTag) => applicationAgeTag === ageTag);

        return (
            <div key={ageTag} className="me-3">
                <input
                    className="form-check-input me-2"
                    type="checkbox"
                    checked={checked}
                    onChange={checkboxChangeHandler}
                    value={ageTag}
                />
                <label className="form-check-label">{ageTag}</label>
            </div>
        );
    });

    const applicationAgeTags = ageTagsForTargetGroup.map((ageTag) => {
        const checked = application.document.ageTags.some((applicationAgeTag) => applicationAgeTag === ageTag);

        return (
            <div key={ageTag} className="me-3">
                <input className="form-check-input me-2" type="checkbox" checked={checked} disabled />
                <label className="form-check-label">{ageTag}</label>
            </div>
        );
    });

    return (
        <Modal show={show} size="lg" onHide={onHide}>
            <Modal.Header closeButton>
                <h4>Justera målgrupper för "{application.document.eventName}"</h4>
            </Modal.Header>
            <Modal.Body>
                <InfoMessage
                    message={`Här kan du justera vilka målgrupper som tillfällen av evenemanget "${application.document.eventName}" valda i fliken "${targetGroupNames[targetGroup]}" är riktade till. 
                              Valen du gör här påverkar inte innehållet i aktörens intresseanmälan. Under "Målgrupper i intresseanmälan" ser du vilka målgrupper aktören angav för evenemanget.`}
                />
                <label>Justerade målgrupper</label>
                <div className="d-flex flex-wrap">{ageTagOptions}</div>

                <label className="mt-4">Målgrupper i intresseanmälan</label>
                <div className="d-flex flex-wrap">{applicationAgeTags}</div>
            </Modal.Body>
        </Modal>
    );
}

export default AdjustSelectionAgeTagsModal;
