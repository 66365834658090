import React, { CSSProperties } from "react";
import Application from "../../../types/Application";
import { getTotalNumberOfOccasions } from "../../../common/applicationUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type SiteApplicationSelectionTableRowProps = {
    application: Application;
    onTableRowClick: () => void;
};

const iconStyles: CSSProperties = {
    fontSize: 20
};

function SiteApplicationSelectionTableRow({ application, onTableRowClick }: SiteApplicationSelectionTableRowProps) {
    const remainingNumberOfOccasionsToPlan = Math.max(
        0,
        getTotalNumberOfOccasions(application) - application.numberOfPlannedOccasions
    );

    return (
        <tr onClick={onTableRowClick}>
            <td>{application.actorName}</td>
            <td>{application.document.eventName}</td>
            <td>{application.groupedAgeTags.join(", ")}</td>
            <td>
                {application.hasMatchingTechnology && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Spelplatsen uppfyller evenemangets teknikkrav</Tooltip>}
                    >
                        <i style={iconStyles} className="bi bi-mic-fill me-1 text-primary"></i>
                    </OverlayTrigger>
                )}
                {application.hasMatchingDimensions && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Spelplatsen uppfyller evenemangets dimensionskrav</Tooltip>}
                    >
                        <i style={iconStyles} className="bi bi-rulers me-1 text-secondary"></i>
                    </OverlayTrigger>
                )}

                {application.hasMatchingDates && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Spelplatsen och evenemanget har gemensamma möjliga datum</Tooltip>}
                    >
                        <i style={iconStyles} className="bi bi-calendar-check-fill me-1 text-success"></i>
                    </OverlayTrigger>
                )}
                {application.hasMatchingSiteType && (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Spelplatsen uppfyller evenemangets krav på typ av spelplats</Tooltip>}
                    >
                        <i style={{ ...iconStyles, color: "#6610F2" }} className="bi bi-house-check-fill"></i>
                    </OverlayTrigger>
                )}
            </td>
            <td>
                <input
                    className="form-control text-end pe-0"
                    type="number"
                    value={getTotalNumberOfOccasions(application)}
                    disabled
                />
            </td>
            <td>
                <input
                    className="form-control text-end pe-0"
                    type="number"
                    value={remainingNumberOfOccasionsToPlan}
                    disabled
                />
            </td>
        </tr>
    );
}

export default SiteApplicationSelectionTableRow;
