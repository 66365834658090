import { ChartOptions } from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    applicationBelongsToTargetGroup,
    getAdjustedAgeTagsByTargetGroup,
    getAdjustedAgeTagsWithSelectedOccasions,
    getAgeTagsByTargetGroup,
    getNumberOfOccasionsByTargetGroup,
    getRelevantTargetGroupsByAgeTag
} from "../../common/applicationUtils";
import { AgeTag, TargetGroup } from "../../common/enums";
import Application from "../../types/Application";

type AgeChartProps = {
    applications: Application[];
    targetGroup: string;
    isCulturalManagement: boolean;
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        y: {
            title: {
                display: true,
                text: "Antal tillfällen",
                font: {
                    size: 14,
                    weight: "bold"
                }
            }
        },
        x: {
            title: {
                display: true,
                text: "Åldrar",
                font: {
                    size: 14,
                    weight: "bold"
                }
            }
        }
    }
};

const chartColors = [
    "#56AEE2",
    "#E256AE",
    "#8A56E2",
    "#56E289",
    "#56E2CF",
    "#5668E2",
    "#CF56E2",
    "#E25668",
    "#E28956",
    "#E2CF56",
    "#AEE256",
    "#68E256"
];

function AgeChart({ applications, targetGroup, isCulturalManagement }: AgeChartProps) {
    const [labels, setLabels] = useState<string[]>([]);

    //
    // ageTagCounts determines the height of each column in the column chart.
    // If ageTagCounts[AgeTag.Year9] === 1, then column "Åk9" will have height 1.
    //
    const [ageTagCounts, setAgeTagCounts] = useState<number[]>([]);

    useEffect(() => {
        const currentApplications = applications.filter((a) =>
            applicationBelongsToTargetGroup(a, targetGroup as TargetGroup, isCulturalManagement)
        );

        const targetGroupAgeTags = getAgeTagsByTargetGroup(targetGroup as TargetGroup);

        const updatedAgeTagCount: { [ageTag: string]: number } = {};

        targetGroupAgeTags.forEach((tgat) => (updatedAgeTagCount[tgat] = 0));

        currentApplications.forEach((a) => {
            const adjustedAgeTags =
                targetGroup === TargetGroup.Total
                    ? getAdjustedAgeTagsWithSelectedOccasions(a, isCulturalManagement)
                    : getAdjustedAgeTagsByTargetGroup(a, targetGroup as TargetGroup, isCulturalManagement);

            adjustedAgeTags.forEach((at) => {
                if (at === AgeTag.SpecialNeedsSchoolOld) {
                    at = AgeTag.SpecialNeedsSchool;
                }

                if (updatedAgeTagCount[at] || updatedAgeTagCount[at] === 0) {
                    if (targetGroup === TargetGroup.Total) {
                        const relevantTargetGroups = getRelevantTargetGroupsByAgeTag(at as AgeTag);
                        relevantTargetGroups.forEach((tg) => {
                            updatedAgeTagCount[at] += getNumberOfOccasionsByTargetGroup(a, tg, isCulturalManagement);
                        });
                    } else {
                        updatedAgeTagCount[at] += getNumberOfOccasionsByTargetGroup(
                            a,
                            targetGroup,
                            isCulturalManagement
                        );
                    }
                }
            });
        });

        const newAgeTagCounts: number[] = [];
        const newLabels: string[] = [];

        Object.keys(updatedAgeTagCount).forEach((label) => {
            newAgeTagCounts.push(updatedAgeTagCount[label]);
            newLabels.push(label);
        });

        setAgeTagCounts(newAgeTagCounts);
        setLabels(newLabels);
    }, [applications]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Antal tillfällen",
                data: ageTagCounts,
                backgroundColor: chartColors,
                borderColor: chartColors,
                borderWidth: 1
            }
        ]
    };

    return <Bar options={options} data={data} />;
}

export default AgeChart;
