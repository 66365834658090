import React, { ChangeEvent } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import Site from "../../types/Site";
import { formatTime, getCaptionText, getWeekDayName, isSameDay } from "../../common/dateUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InfoMessage from "../Common/Messages/InfoMessage";
import { postAvailableSiteTime } from "../../common/apiClient";

type CalenderModalProps = {
    onError: (errorMessage: string) => void;
    applicationPeriod: ApplicationPeriod;
    site: Site;
    onSiteUpdated: (updatedSite: Site) => void;
};

export const siteSelectionCalenderModalId = "site-selection-modal-id";

const availabilityChangeErrorMessage =
    "Något gick fel när tillgängligheten uppdaterades. Vänligen uppdatera sidan och försök igen. Kontakta support om problemet kvarstår.";

export default function CalenderModal({ onError, applicationPeriod, site, onSiteUpdated }: CalenderModalProps) {
    const handleDateClicked = (clickedDate: Date) => {
        const dateWasPreviouslySelected = site.availableSiteDates.some(
            (a) => a.applicationPeriodId === applicationPeriod.id && isSameDay(new Date(a.availableDate), clickedDate)
        );

        let updatedSite: Site;

        if (dateWasPreviouslySelected) {
            deleteAvailableSiteDate(clickedDate);

            const previouslySelectedIndex = site.availableSiteDates.findIndex(
                (a) =>
                    a.applicationPeriodId === applicationPeriod.id && isSameDay(new Date(a.availableDate), clickedDate)
            );

            updatedSite = { ...site };
            updatedSite.availableSiteDates.splice(previouslySelectedIndex, 1);
        } else {
            postAvailableSiteDate(clickedDate);

            const newAvailableSiteDate: AvailableSiteDate = {
                applicationPeriodId: applicationPeriod.id,
                siteId: site.id,
                availableDate: clickedDate
            };

            updatedSite = { ...site };
            updatedSite.availableSiteDates.push(newAvailableSiteDate);
        }

        onSiteUpdated(updatedSite);
    };

    const deleteAvailableSiteDate = async (date: Date) => {
        const requestInit: RequestInit = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                RequestVerificationToken: (
                    document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement
                ).value
            }
        };

        const deleteResult = await fetch(
            `/api/AvailableSiteDate/${applicationPeriod.id}/${site.id}/${date.getFullYear()}/${
                date.getMonth() + 1
            }/${date.getDate()}`,
            requestInit
        );

        if (!deleteResult.ok) {
            const errorMessage = await deleteResult.text();
            console.error(errorMessage);
            onError(availabilityChangeErrorMessage);
        }
    };

    const postAvailableSiteDate = async (date: Date) => {
        const requestBody = {
            applicationPeriodId: applicationPeriod.id,
            siteId: site.id,
            availableDate: date
        };

        const requestInit: RequestInit = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                RequestVerificationToken: (
                    document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement
                ).value
            },
            body: JSON.stringify(requestBody)
        };

        const postResult = await fetch("/api/AvailableSiteDate", requestInit);

        if (!postResult.ok) {
            const errorMessage = await postResult.text();
            console.error(errorMessage);
            onError(availabilityChangeErrorMessage);
        }
    };

    const availableSiteTimeIndex = site.availableSiteTimes.findIndex(
        (ast) => ast.applicationPeriodId === applicationPeriod.id
    );
    const availableSiteTime = availableSiteTimeIndex >= 0 ? site.availableSiteTimes[availableSiteTimeIndex] : undefined;

    const availableFromTimeChangeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
        const availableFrom = event.target.value;

        const newSiteTime: AvailableSiteTime = {
            siteId: site.id,
            applicationPeriodId: applicationPeriod.id,
            availableFrom: availableFrom,
            availableTo: availableSiteTime ? availableSiteTime.availableTo : ""
        };

        if (availableSiteTimeIndex >= 0) {
            site.availableSiteTimes.splice(availableSiteTimeIndex, 1);
        }

        site.availableSiteTimes.push(newSiteTime);

        onSiteUpdated({ ...site });
    };

    const availableToTimeChangeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
        const availableTo = event.target.value;

        const newSiteTime: AvailableSiteTime = {
            siteId: site.id,
            applicationPeriodId: applicationPeriod.id,
            availableTo: availableTo,
            availableFrom: availableSiteTime ? availableSiteTime.availableFrom : ""
        };

        if (availableSiteTimeIndex >= 0) {
            site.availableSiteTimes.splice(availableSiteTimeIndex, 1);
        }

        site.availableSiteTimes.push(newSiteTime);

        onSiteUpdated({ ...site });
    };

    const availableTimeBlurHandler = async () => {
        const toAndFromTimesAreProvided = availableSiteTime.availableFrom && availableSiteTime.availableTo;
        if (toAndFromTimesAreProvided) {
            const result = await postAvailableSiteTime(availableSiteTime);

            if (result.errorMessage) {
                console.error(result.errorMessage);
                onError(availabilityChangeErrorMessage);
            }
        }
    };

    const disableCalender = !availableSiteTime || !availableSiteTime.availableFrom || !availableSiteTime.availableTo;

    const selectedDates = site.availableSiteDates
        .filter((a) => a.applicationPeriodId === applicationPeriod.id)
        .map((a) => new Date(a.availableDate));

    return (
        <div className="modal modal-lg fade" tabIndex={-1} id={siteSelectionCalenderModalId}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Tillgänglighet för {site.name}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="ps-4 pe-4 pt-2">
                            {disableCalender ? (
                                <InfoMessage message="Du måste ange en tillgänglig tid för spelplatsen innan du väljer datum." />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="d-flex align-items-stretch">
                            <DayPicker
                                mode="multiple"
                                weekStartsOn={1}
                                formatters={{
                                    formatWeekdayName: getWeekDayName,
                                    formatCaption: getCaptionText
                                }}
                                selected={selectedDates}
                                onDayClick={handleDateClicked}
                                disabled={{
                                    before: disableCalender ? new Date() : new Date(applicationPeriod.occasionsFrom),
                                    after: disableCalender ? new Date() : new Date(applicationPeriod.occasionsTo)
                                }}
                                defaultMonth={new Date(applicationPeriod.occasionsFrom)}
                            />
                            <section className="w-100 mt-4">
                                <div className="d-flex">
                                    <h5 className="me-1">Tillgänglig tid</h5>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                Här anger du när på dagen spelplatsen är tillgänglig. Tiden gäller för
                                                samtliga av de givna datumen.
                                            </Tooltip>
                                        }
                                    >
                                        <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip"></i>
                                    </OverlayTrigger>
                                </div>
                                <div className="d-flex">
                                    <div className="me-4">
                                        <label className="form-label">Spelplatsen tillgänglig från</label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            onChange={availableFromTimeChangeHandler}
                                            onBlur={availableTimeBlurHandler}
                                            value={availableSiteTime ? formatTime(availableSiteTime.availableFrom) : ""}
                                        />
                                    </div>
                                    <div>
                                        <label className="form-label">Spelplatsen tillgänglig till</label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            onChange={availableToTimeChangeHandler}
                                            onBlur={availableTimeBlurHandler}
                                            value={availableSiteTime ? formatTime(availableSiteTime.availableTo) : ""}
                                        />
                                    </div>
                                </div>
                                <div></div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
