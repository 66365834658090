import Swal from "sweetalert2";

export default class ApplicationPeriodsIndex {
    enable() {
        this.publishToCulturalArtsBooking();
    }

    publishToCulturalArtsBooking() {
        const requestVerificationToken = document.querySelector(
            'input[name="__RequestVerificationToken"]'
        ) as HTMLInputElement;

        if (!requestVerificationToken) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const publishButtons = document.querySelectorAll<HTMLButtonElement>("button[data-publish]");

        publishButtons.forEach((button) => {
            button.addEventListener("click", async (e) => {
                e.preventDefault();

                const text = button.dataset["publish"];

                const url = button.dataset["url"];

                const swalResult = await Swal.fire({
                    title: "Publicera evenemang",
                    text: text,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Publicera till KuBo",
                    cancelButtonText: "Avbryt"
                });

                if (swalResult.isConfirmed) {
                    const headers = { RequestVerificationToken: requestVerificationToken.value };

                    const responsePromise = fetch(url, { method: "POST", headers: headers });

                    const response = await responsePromise;

                    if (response.ok) {
                        button.remove();

                        Swal.fire({
                            title: "Publiceringen till KuBo har startat",
                            text: "Detta kan ta några minuter. Du behöver inte vänta på denna sida under tiden. När publiceringen är färdig kommer evenemangen synas i KuBo.",
                            icon: "success"
                        });
                    } else {
                        const error = await response.text();
                        console.error(error);

                        Swal.fire({
                            title: "Evenemangen kunde inte publiceras",
                            text: "Kontakta support om problement kvarstår.",
                            icon: "error"
                        });
                    }
                }
            });
        });
    }
}
