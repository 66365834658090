import { CSSProperties } from "react";

/**
 * These styles are used in places where css-files are not available. Such as in texts that will be copied and pasted into other applications,
 * and mails being sent from the system.
 */

export const defaultMarginBottom = "24px";
export const defaultFontSize = "11pt";
export const defaultFontFamily = "Calibri, sans-serif";
export const defaultTextStyles: CSSProperties = { fontFamily: defaultFontFamily, fontSize: defaultFontSize };

export const tableCellStyles: CSSProperties = {
    border: "1px solid black",
    borderCollapse: "collapse",
    fontFamily: defaultFontFamily,
    fontSize: "8pt",
    wordWrap: "break-word",
};

export const tableStyles: CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed"
};

export const sectionHeaderStyles: CSSProperties = {
    fontWeight: "bold",
    fontFamily: defaultFontFamily
};

export const tableCellHeaderStyles: CSSProperties = {
    fontStyle: "italic"
};
