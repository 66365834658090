import React, { useEffect, useRef, useState } from "react";
import Application from "../../types/Application";
import { Modal } from "react-bootstrap";
import InfoMessage from "../Common/Messages/InfoMessage";
import { postAdjustedOccasionPrice } from "../../common/apiClient";
import SuccessMessage from "../Common/Messages/SuccessMessage";

export const adjustPriceModalId = "adjust-price-modal";

type AdjustPriceModalProps = {
    application: Application;
    show: boolean;
    onHide: () => void;
    onApplicationChange: (application: Application) => void;
    onError: (errorMessage: string) => void;
};

function    AdjustPriceModal({ application, show, onHide, onApplicationChange, onError }: AdjustPriceModalProps) {
    const [adjustedOccasionPriceInput, setAdjustedOccasionPriceInput] = useState<string>(
        application && application.adjustedOccasionPrice ? application.adjustedOccasionPrice.toString() : ""
    );
    const [priceIsSaved, setPriceIsSaved] = useState(false);

    useEffect(() => {
        setPriceIsSaved(false);

        setAdjustedOccasionPriceInput(
            application && application.adjustedOccasionPrice ? application.adjustedOccasionPrice.toString() : ""
        );
    }, [show]);

    if (!application) return <></>;

    const saveAdjustedPriceHandler = async () => {
        let adjustedOccasionPrice: number | undefined = undefined;
        if (adjustedOccasionPriceInput !== "") {
            adjustedOccasionPrice = Number.parseInt(adjustedOccasionPriceInput);
        }

        application.adjustedOccasionPrice = adjustedOccasionPrice;
        onApplicationChange(application);

        const postResult = await postAdjustedOccasionPrice(application.id, adjustedOccasionPrice);

        if (postResult.errorMessage) {
            console.error(postResult.errorMessage);
            onError("Något gick fel när priset uppdaterades. Kontakta support om problemet kvarstår.");
            return;
        }

        setPriceIsSaved(true);
    };

    const adjustedOccasionPriceChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPriceIsSaved(false);
        setAdjustedOccasionPriceInput(event.target.value);
    };

    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <InfoMessage
                        message="Här kan du ange ett anpassat pris för ett tillfälle av evenemanget. 
                    Detta pris komer att användas i beräkningen av evenemangets totalpris. 
                    Det anpassade priset påverkar inte information som finns angiven i aktörens intresseanmälan."
                    />

                    <div className="form-group mb-5 d-flex align-items-end">
                        <div className="me-2">
                            <label className="fw-bold">Pris per tillfälle</label>
                            <input
                                className="form-control me-1 hide-input-scroll"
                                type="number"
                                min={0}
                                value={adjustedOccasionPriceInput}
                                onChange={adjustedOccasionPriceChangeHandler}
                            />
                        </div>
                        {priceIsSaved ? (
                            <button className="btn btn-success">Priset har uppdaterats</button>
                        ) : (
                            <button className="btn btn-primary" onClick={saveAdjustedPriceHandler}>
                                Spara pris
                            </button>
                        )}
                    </div>

                    <h6 className="mb-2">Prisinformation från intresseanmälan</h6>
                    <div className="mb-2">
                        <input
                            className="form-check-input me-1"
                            type="checkbox"
                            disabled
                            checked={application.document.isOwnStage}
                        />{" "}
                        Evenemanget erbjuds på fast scen/egen regi
                        {application.document.isOwnStage && (
                            <div className="mb-3 container-fluid row m-0 p-0">
                                <div className="col-4 ps-0">
                                    <label className="form-label mb-0">Biljettpris</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={`${application.document.ticketPrice.toLocaleString()} kronor`}
                                        type="text"
                                    />
                                </div>
                                <div className="col-4">
                                    <label className="form-label mb-0">Antal platser</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={application.document.numberOfSeats}
                                        type="text"
                                    />
                                </div>
                                <div className="col-4 pe-0">
                                    <label className="form-label mb-0">Total biljettpris</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={`${(
                                            application.document.ticketPrice * application.document.numberOfSeats
                                        ).toLocaleString()} kronor`}
                                        type="text"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-2">
                        <input
                            className="form-check-input me-1"
                            type="checkbox"
                            disabled
                            checked={application.document.isVisiting}
                        />{" "}
                        Evenemanget erbjuds som uppsökande evenemang
                        {application.document.isVisiting && (
                            <div className="mb-3 container-fluid row m-0 p-0">
                                <div className="col-4 ps-0">
                                    <label className="form-label mb-0">Föreställningspris</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={`${application.document.occasionPrice.toLocaleString()} kronor`}
                                        type="text"
                                    />
                                </div>
                                <div className="col-4">
                                    <label className="form-label mb-0">Pris på efterföljande tillfälle samma dag</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={
                                            application.document.occasionPrice2
                                                ? `${application.document.occasionPrice2.toLocaleString()} kronor`
                                                : ""
                                        }
                                        type="text"
                                    />
                                </div>
                                <div className="col-4 pe-0">
                                    <label className="form-label mb-0">Totalt föreställningspris</label>
                                    <input
                                        className="form-control"
                                        disabled
                                        value={`${(
                                            application.document.occasionPrice + application.document.occasionPrice2
                                        ).toLocaleString()} kronor`}
                                        type="text"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {application.document.priceComment && (
                        <div className="mb-2">
                            <label className="form-label mb-0">Kommentar till pris</label>
                            <textarea
                                className="form-control"
                                disabled
                                value={application.document.priceComment ?? ""}
                            ></textarea>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdjustPriceModal;
