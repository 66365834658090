import React, { useEffect, useRef, useState } from "react";
import {
    getBudgetByTargetGroup,
    getCurrentCostByTargetGroup,
    getTotalCostForEvents,
    getTotalBudget,
    getCurrentElementarySchoolCost
} from "../../common/applicationUtils";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import { ArcElement, BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from "chart.js";
import GenreChart from "./GenreChart";
import AgeChart from "./AgeChart";
import ProgressBar from "../Common/ProgressBar";
import { TargetGroup } from "../../common/enums";
import { Accordion, Carousel } from "react-bootstrap";
import Budgets from "./Budgets";

type InformationPanelProps = {
    applicationPeriod: ApplicationPeriod;
    targetGroup: string;
    isCulturalManagement: boolean;
};

Chart.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

function InformationPanel({ applicationPeriod, targetGroup, isCulturalManagement }: InformationPanelProps) {
    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);

    if (!applicationPeriod) return;

    const carouselSlideHandler = () => {
        //
        // This is to prevent the height of the accordion to change when switching carousel items.
        //
        const carouselItems = Array.from(document.querySelectorAll<HTMLElement>(".carousel-item"));
        let maxItemHeight = Math.max(...carouselItems.map((ci) => ci.clientHeight));
        carouselItems.forEach((item) => {
            item.style.height = `${maxItemHeight}px`;
        });
    };

    const applications = applicationPeriod.applications;

    const targetGroupBudget = getBudgetByTargetGroup(
        applicationPeriod,
        targetGroup as TargetGroup,
        isCulturalManagement
    );
    const targetGroupCurrentCost = getCurrentCostByTargetGroup(
        applications,
        targetGroup as TargetGroup,
        isCulturalManagement
    );
    const targetGroupRemainingBudget = targetGroupBudget - targetGroupCurrentCost;

    const totalBudget = getTotalBudget(applicationPeriod, isCulturalManagement);
    const totalCurrentCost = getTotalCostForEvents(applications, isCulturalManagement);
    const totalRemainingBudget = totalBudget - totalCurrentCost;

    let elementarySchoolBudgetElement: JSX.Element;
    const targetGroupBelongsToElementarySchool =
        targetGroup == TargetGroup.SchoolLower ||
        targetGroup == TargetGroup.SchoolMiddle ||
        targetGroup == TargetGroup.SchoolUpper;

    if (targetGroupBelongsToElementarySchool) {
        const elementarySchoolBudget =
            getBudgetByTargetGroup(applicationPeriod, TargetGroup.SchoolLower, isCulturalManagement) +
            getBudgetByTargetGroup(applicationPeriod, TargetGroup.SchoolMiddle, isCulturalManagement) +
            getBudgetByTargetGroup(applicationPeriod, TargetGroup.SchoolUpper, isCulturalManagement);

        const elementarySchoolCurrentCost = getCurrentElementarySchoolCost(applications, isCulturalManagement);

        const elementarySchoolRemainingBudget = elementarySchoolBudget - elementarySchoolCurrentCost;

        elementarySchoolBudgetElement = (
            <div className="d-flex align-items-center flex-column">
                Återstående grundskolebudget: {elementarySchoolRemainingBudget.toLocaleString()}kr av{" "}
                {elementarySchoolBudget.toLocaleString()}kr
                <ProgressBar progress={elementarySchoolCurrentCost / elementarySchoolBudget} />
            </div>
        );
    }

    return (
        <Accordion>
            <Accordion.Item eventKey="0" className="statistics-panel">
                <Accordion.Body className="p-0">
                    <Carousel
                        activeIndex={activeCarouselIndex}
                        controls={false}
                        indicators={false}
                        onSelect={setActiveCarouselIndex}
                        onSlide={carouselSlideHandler}
                        interval={null}
                    >
                        <Carousel.Item>
                            <div className="d-flex container-fluid pe-5">
                                <div className="col-6 m-3">
                                    <GenreChart
                                        applications={applications}
                                        targetGroup={targetGroup}
                                        isCulturalManagement={isCulturalManagement}
                                    />
                                </div>
                                <div className="col-6 m-3">
                                    <AgeChart
                                        applications={applications}
                                        targetGroup={targetGroup}
                                        isCulturalManagement={isCulturalManagement}
                                    />
                                </div>
                            </div>
                            {targetGroup === TargetGroup.Total && (
                                <div style={{ position: "absolute", right: 0, bottom: 0, padding: "8px 8px" }}>
                                    <button className="btn btn-primary" onClick={() => setActiveCarouselIndex(1)}>
                                        Visa budgetar
                                        <i className="bi bi-caret-right-fill ms-1"></i>
                                    </button>
                                </div>
                            )}
                        </Carousel.Item>
                        {targetGroup === TargetGroup.Total && (
                            <Carousel.Item>
                                <Budgets
                                    applicationPeriod={applicationPeriod}
                                    applications={applications}
                                    isCulturalManagement={isCulturalManagement}
                                />
                                <div style={{ position: "absolute", left: 0, bottom: 0, padding: "8px 8px" }}>
                                    <button className="btn btn-primary" onClick={() => setActiveCarouselIndex(0)}>
                                        <i className="bi bi-caret-left-fill me-1"></i>
                                        Visa fördelning
                                    </button>
                                </div>
                            </Carousel.Item>
                        )}
                    </Carousel>
                </Accordion.Body>
                <Accordion.Header>
                    <div className="d-flex justify-content-center w-100">
                        {targetGroup != TargetGroup.Total && (
                            <div className="me-5 d-flex align-items-center flex-column">
                                Återstående budget: {targetGroupRemainingBudget.toLocaleString()}kr av{" "}
                                {targetGroupBudget.toLocaleString()}kr
                                <ProgressBar progress={targetGroupCurrentCost / targetGroupBudget} />
                            </div>
                        )}
                        {targetGroupBelongsToElementarySchool && elementarySchoolBudgetElement}
                        <div className="ms-5 d-flex align-items-center flex-column">
                            Återstående total budget: {totalRemainingBudget.toLocaleString()}kr av{" "}
                            {totalBudget.toLocaleString()}kr
                            <ProgressBar progress={totalCurrentCost / totalBudget} />
                        </div>
                    </div>
                </Accordion.Header>
            </Accordion.Item>
        </Accordion>
    );
}

export default InformationPanel;
