import Swal from "sweetalert2";

export default class ApplicationsIndex {
    enable() {
        this.copyApplication();
        this.markApproved();
        this.unmarkApproved();
        this.markNew();
        this.unmarkNew();
        this.markPremiere();
        this.unmarkPremiere();
        this.markFinancedExternally();
        this.unmarkFinancedExternally();
        this.filter();
        this.unlockPossibleOccasionDates();
        this.lockPossibleOccasionDates();
        this.reject();
    }

    copyApplication() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const deleteButtons = document.querySelectorAll("button[data-copy]");
        for (let i = 0; i < deleteButtons.length; i++) {
            const copyButton = deleteButtons[i] as HTMLButtonElement;
            copyButton.addEventListener("click", (e) => {
                e.preventDefault();
                const text = copyButton.dataset["copy"];
                const url = copyButton.dataset["url"];
                Swal.fire({
                    title: "Intresseanmälan",
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };
                        const response = await fetch(url, { method: "POST", headers });
                        if (response.ok) {
                            const responseHeaderLocation = response.headers.get("location");
                            if (responseHeaderLocation) {
                                window.location.href = responseHeaderLocation;
                            } else {
                                window.location.reload();
                            }
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    markApproved() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const approveButtons = document.querySelectorAll("button[data-approve]");
        for (let i = 0; i < approveButtons.length; i++) {
            const approveButton = approveButtons[i] as HTMLButtonElement;
            approveButton.addEventListener("click", (e) => {
                e.preventDefault();
                const text = approveButton.dataset["approve"];
                const url = approveButton.dataset["url"];
                Swal.fire({
                    title: "Intresseanmälan",
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };
                        const response = await fetch(url, { method: "PUT", headers });
                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    unmarkApproved() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const approveButtons = document.querySelectorAll("button[data-remove-approval]");
        for (let i = 0; i < approveButtons.length; i++) {
            const approveButton = approveButtons[i] as HTMLButtonElement;
            approveButton.addEventListener("click", (e) => {
                e.preventDefault();
                const text = approveButton.dataset["removeApproval"];
                const url = approveButton.dataset["url"];
                Swal.fire({
                    title: "Intresseanmälan",
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };
                        const response = await fetch(url, { method: "PUT", headers });
                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    markNew() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const markNewButtons = document.querySelectorAll("button[data-mark-new]");

        for (let i = 0; i < markNewButtons.length; i++) {
            const markNewButton = markNewButtons[i] as HTMLButtonElement;
            markNewButton.addEventListener("click", (e) => {
                e.preventDefault();
                const text = markNewButton.dataset["markNew"];
                const url = markNewButton.dataset["url"];
                Swal.fire({
                    title: "Intresseanmälan",
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };
                        const response = await fetch(url, { method: "PUT", headers });
                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    unmarkNew() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const removeNewMarkingButtons = document.querySelectorAll("button[data-remove-new]");

        for (let i = 0; i < removeNewMarkingButtons.length; i++) {
            const removeNewMarkingButton = removeNewMarkingButtons[i] as HTMLButtonElement;
            removeNewMarkingButton.addEventListener("click", (e) => {
                e.preventDefault();
                const text = removeNewMarkingButton.dataset["removeNew"];
                const url = removeNewMarkingButton.dataset["url"];
                Swal.fire({
                    title: "Intresseanmälan",
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };
                        const response = await fetch(url, { method: "PUT", headers });
                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    markPremiere() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const markPremiereButtons = document.querySelectorAll("button[data-mark-premiere]");

        for (let i = 0; i < markPremiereButtons.length; i++) {
            const markNewButton = markPremiereButtons[i] as HTMLButtonElement;
            markNewButton.addEventListener("click", (e) => {
                e.preventDefault();
                const text = markNewButton.dataset["markPremiere"];
                const url = markNewButton.dataset["url"];
                Swal.fire({
                    title: "Intresseanmälan",
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };
                        const response = await fetch(url, { method: "PUT", headers });
                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    unmarkPremiere() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const removePremiereMarkingButtons = document.querySelectorAll("button[data-remove-premiere]");

        for (let i = 0; i < removePremiereMarkingButtons.length; i++) {
            const markNewButton = removePremiereMarkingButtons[i] as HTMLButtonElement;
            markNewButton.addEventListener("click", (e) => {
                e.preventDefault();
                const text = markNewButton.dataset["removePremiere"];
                const url = markNewButton.dataset["url"];
                Swal.fire({
                    title: "Intresseanmälan",
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };
                        const response = await fetch(url, { method: "PUT", headers });
                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    markFinancedExternally() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const markFinancedExternallyButtons = document.querySelectorAll("button[data-mark-financed-externally]");

        for (let i = 0; i < markFinancedExternallyButtons.length; i++) {
            const markNewButton = markFinancedExternallyButtons[i] as HTMLButtonElement;
            markNewButton.addEventListener("click", (e) => {
                e.preventDefault();
                const text = markNewButton.dataset.markFinancedExternally;
                const url = markNewButton.dataset.url;
                Swal.fire({
                    title: "Intresseanmälan",
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };
                        const response = await fetch(url, { method: "PUT", headers });
                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    unmarkFinancedExternally() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const removeFinancedExternallyButtons = document.querySelectorAll("button[data-remove-financed-externally]");

        for (let i = 0; i < removeFinancedExternallyButtons.length; i++) {
            const markNewButton = removeFinancedExternallyButtons[i] as HTMLButtonElement;
            markNewButton.addEventListener("click", (e) => {
                e.preventDefault();
                const text = markNewButton.dataset.removeFinancedExternally;
                const url = markNewButton.dataset.url;
                Swal.fire({
                    title: "Intresseanmälan",
                    text: text,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };
                        const response = await fetch(url, { method: "PUT", headers });
                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    filter() {
        const filterForm = document.getElementById("filter-form") as HTMLFormElement;

        const formSelects = filterForm.querySelectorAll("select");

        formSelects.forEach((select) =>
            select.addEventListener("change", () => {
                const filterData = new FormData(filterForm);
                const queryParams = new URLSearchParams(filterData as any);

                window.location.search = queryParams.toString();
            })
        );
    }

    unlockPossibleOccasionDates() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const buttons = document.querySelectorAll("button[data-unlock-possible-occasions]");

        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i] as HTMLButtonElement;
            const url = button.dataset.url;

            button.addEventListener("click", (e) => {
                e.preventDefault();

                Swal.fire({
                    title: "Lås upp möjliga datum",
                    icon: "question",
                    width: "800px",
                    html: `
                    <div>
                        <div class="alert alert-info">
                            Här kan du låsa upp möjligheten för aktören att ange möjliga datum för evenemanget.  
                            Nedan anger du hur länge möjligheten att justera datum ska vara öppen.
                        </div>
                        <div class="w-25 m-auto d-flex flex-column align-items-start ">
                            <label style="font-size: 12pt;">Öppen t.o.m.</label>
                            <input type="date" id="possible-occasion-dates" class="form-control" />
                        </div>
                    </div>
                    `,

                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Lås upp möjliga datum",
                    cancelButtonText: "Avbryt",
                    preConfirm: () => {
                        const datePicker = document.getElementById("possible-occasion-dates") as HTMLInputElement;

                        const selectedDate = datePicker.value;

                        if (!selectedDate) {
                            Swal.showValidationMessage("Du måste ange ett datum");
                            return false;
                        }

                        return new Date(selectedDate);
                    }
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = {
                            RequestVerificationToken: antiforgeryInput.value,
                            "Content-Type": "application/json"
                        };
                        const body = JSON.stringify(result.value);

                        const response = await fetch(url, { method: "PUT", headers, body: body });

                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    lockPossibleOccasionDates() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const buttons = document.querySelectorAll("button[data-lock-possible-occasions]");

        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i] as HTMLButtonElement;
            const url = button.dataset.url;

            button.addEventListener("click", (e) => {
                e.preventDefault();

                Swal.fire({
                    title: "Intresseanmälan",
                    text: "Vill du låsa möjligheten för aktören att redigera möjliga datum i intresseanmälan?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ja",
                    cancelButtonText: "Nej"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const headers = { RequestVerificationToken: antiforgeryInput.value };

                        const response = await fetch(url, { method: "PUT", headers });

                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }

    reject() {
        const antiforgeryInput = document.querySelector('input[name="__RequestVerificationToken"]') as HTMLInputElement;
        if (antiforgeryInput == null) {
            alert("Det finns ingen AntiforgeryToken");
            return;
        }

        const rejectSubmissionButtons = document.querySelectorAll("button[data-reject]");

        for (let i = 0; i < rejectSubmissionButtons.length; i++) {
            const button = rejectSubmissionButtons[i] as HTMLButtonElement;
            const url = button.dataset.url;

            button.addEventListener("click", (e) => {
                e.preventDefault();

                Swal.fire({
                    title: "Backa intresseanmälan",
                    width: "800px",
                    html: `
                    <div>
                        <div class="alert alert-info">
                            Här kan du backa aktörens intresseanmälan. Om du lämnar en kommentar kommer denna skickas till aktören. Om ingen kommentar lämnas 
                            kommer inte aktören notifieras om att deras intresseanmälan har blivit backad. 
                        </div>
                        <div class="w-50 m-auto d-flex flex-column align-items-start ">
                            <label style="font-size: 12pt;">Kommentar</label>
                            <textarea rows="3" class="form-control" id="rejection-comment"></textarea>
                        </div>
                    </div>
                    `,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Backa intresseanmälan",
                    cancelButtonText: "Avbryt"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const rejectionComment = document.getElementById("rejection-comment") as HTMLTextAreaElement;

                        const body = JSON.stringify(rejectionComment.value);

                        const headers = {
                            RequestVerificationToken: antiforgeryInput.value,
                            "Content-Type": "application/json"
                        };

                        const response = await fetch(url, { method: "PUT", headers, body });

                        if (response.ok) {
                            window.location.reload();
                        } else {
                            const data = await response.text();
                            alert(data);
                        }
                    }
                });
            });
        }
    }
}
