import React from "react";
import Application from "../../types/Application";
import ApplicationPeriod from "../../types/ApplicationPeriod";
import {
    getBudgetByTargetGroup,
    getCurrentCostByTargetGroup,
    getCurrentElementarySchoolCost,
    getElementarySchoolBudget
} from "../../common/applicationUtils";
import { TargetGroup } from "../../common/enums";
import ProgressBar from "../Common/ProgressBar";

type BudgetsProps = {
    applications: Application[];
    applicationPeriod: ApplicationPeriod;
    isCulturalManagement: boolean;
};

function Budgets({ applications, applicationPeriod, isCulturalManagement }: BudgetsProps) {
    const preSchoolBudget = getBudgetByTargetGroup(applicationPeriod, TargetGroup.PreSchool, isCulturalManagement);
    const preSchoolCurrentCost = getCurrentCostByTargetGroup(applications, TargetGroup.PreSchool, isCulturalManagement);

    const highSchoolBudget = getBudgetByTargetGroup(applicationPeriod, TargetGroup.HighSchool, isCulturalManagement);
    const highSchoolCurrentCost = getCurrentCostByTargetGroup(
        applications,
        TargetGroup.HighSchool,
        isCulturalManagement
    );

    const specialNeedsSchoolBudget = getBudgetByTargetGroup(
        applicationPeriod,
        TargetGroup.SpecialNeedsSchool,
        isCulturalManagement
    );
    const specialNeedsSchoolCurrentCost = getCurrentCostByTargetGroup(
        applications,
        TargetGroup.SpecialNeedsSchool,
        isCulturalManagement
    );

    const otherBudget = getBudgetByTargetGroup(applicationPeriod, TargetGroup.Other, isCulturalManagement);
    const otherCurrentCost = getCurrentCostByTargetGroup(applications, TargetGroup.Other, isCulturalManagement);

    const elementarySchoolBudget = getElementarySchoolBudget(applicationPeriod, isCulturalManagement);

    const elementarySchoolCurrentCost = getCurrentElementarySchoolCost(applications, isCulturalManagement);
    const remainingElementarySchoolBudget = elementarySchoolBudget - elementarySchoolCurrentCost;

    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <div className="row h-50">
                <div className="col-md-4 d-flex flex-column align-items-center mb-4">
                    Återstående förskolebudget: {(preSchoolBudget - preSchoolCurrentCost).toLocaleString()}
                    kr av {preSchoolBudget.toLocaleString()}kr
                    <ProgressBar progress={preSchoolCurrentCost / preSchoolBudget} />
                </div>
                <div className="col-md-4 d-flex flex-column align-items-center mb-4">
                    Återstående grundskolebudget: {remainingElementarySchoolBudget.toLocaleString()}
                    kr av {elementarySchoolBudget.toLocaleString()}kr
                    <ProgressBar progress={elementarySchoolCurrentCost / elementarySchoolBudget} />
                </div>
                <div className="col-md-4 d-flex flex-column align-items-center mb-4">
                    Återstående gymnasiebudget: {(highSchoolBudget - highSchoolCurrentCost).toLocaleString()}
                    kr av {highSchoolBudget.toLocaleString()}kr
                    <ProgressBar progress={highSchoolCurrentCost / highSchoolBudget} />
                </div>
                <div className="col-md-4 d-flex flex-column align-items-center mb-4">
                    Återstående budget anpassad skola:{" "}
                    {(specialNeedsSchoolBudget - specialNeedsSchoolCurrentCost).toLocaleString()}
                    kr av {specialNeedsSchoolBudget.toLocaleString()}kr
                    <ProgressBar
                        progress={specialNeedsSchoolCurrentCost / specialNeedsSchoolBudget}
                    />
                </div>
                <div className="col-md-4 d-flex flex-column align-items-center mb-4">
                    Återstående extern budget: {(otherBudget - otherCurrentCost).toLocaleString()}
                    kr av {otherBudget.toLocaleString()}kr
                    <ProgressBar progress={otherCurrentCost / otherBudget} />
                </div>
            </div>
        </div>
    );
}

export default Budgets;
